*,
*:before,
*:after {
	box-sizing: border-box; }

*:focus,
*:active {
	outline: none; }

a:focus,
a:active {
	outline: none; }

aside,
nav,
footer,
header,
section {
	display: block; }

body {
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%; }

input,
button,
textarea {
	font-size: inherit; }

input::-ms-clear {
	display: none; }

button {
	cursor: pointer;
	background-color: inherit; }

button::-moz-focus-inner {
	padding: 0;
	border: 0; }

a, a:visited {
	text-decoration: none; }

a:hover {
	text-decoration: none; }

.tooltip::after {
  content: attr(data-tooltip); }

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }

._video {
	position: relative;
	overflow: hidden;
	height: 0;
	padding-bottom: 56.25%;
	video,
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; } }

.videobg {
	video,
	iframe,
	object,
	embed {
		position: fixed;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: -100;
		transform: translateX(-50%) translateY(-50%);
		background-size: cover; } }
