.s-equipments {
  display: block;
  position: relative;
  .uk-slider-container {
    padding: 35px;
    margin: -35px;

    @include r(1199) {
      padding: 20px;
      margin: -20px; } } }

.equipment-item {
  display: block;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 20px 9px rgba(0, 0, 0, 0.05);
  &__box {
    display: block;
    position: relative; }
  &__media {
    display: block;
    position: relative;
    a {
      display: block;
      position: relative;
      img {
        width: 100%; } } }
  &__info {
    display: block;
    position: relative;
    padding: 30px;
    @include r(1199) {
      padding: 15px; } }
  &__title {
    display: block;
    position: relative;
    margin-bottom: 10px;
    a {
      color: #222;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      @include fz(24, 16);
      @include tr(0.3s);
      @include h {
        text-decoration: none;
        color: $accent; } } }
  &__links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    a {
      display: inline-block;
      position: relative;
      color: #efb007;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      @include fz(14, 10);
      & + a {
        padding-left: 13px;
        margin-left: 13px;
        border-left: 1px solid #dfdfdf; } } }
  &__list {
    display: block;
    position: relative;
    margin: 30px 0;
    @include r(1199) {
      margin: 15px 0; }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      column-count: 2;
      column-gap: 5px;
      li {
        display: block;
        position: relative;
        color: #222;
        font-size: 13px;
        font-weight: 600;
        line-height: 1.2;
        padding-left: 12px;
        @include fz(13, 10);
        & + li {
          margin-top: 5px; }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 5px;
          left: 0;
          width: 6px;
          height: 6px;
          background-color: #efb007;
          border-radius: 3px; } } } }
  &__price-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    @include r(1199) {
      padding-top: 10px;
      padding-bottom: 10px; } }
  &__price-title {
    color: #222;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 600;
    @include fz(14, 10);
    small {
      display: block;
      font-weight: 400; } }
  &__price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }
  &__price-current {
    color: #222;
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    @include fz(34, 20); }
  &__price-old {
    color: #393939;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: line-through;
    margin-left: 5px;
    @include fz(18, 12); }
  &__prices {
    padding-top: 30px;
    @include r(1199) {
      padding-top: 15px; }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        color: #222;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        padding-left: 25px;
        background-image: url(../img/arrow-circle.png);
        background-position: left center;
        background-repeat: no-repeat;
        @include fz(14, 12);
        @include r(1359) {
          padding-left: 0;
          background: none; } } } }

  &__btn {} }
