@charset "UTF-8";
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-ExtraLight.woff") format("woff"), url("../fonts/Barlow-ExtraLight.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Thin.woff") format("woff"), url("../fonts/Barlow-Thin.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Light.woff") format("woff"), url("../fonts/Barlow-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Regular.woff") format("woff"), url("../fonts/Barlow-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Italic.woff") format("woff"), url("../fonts/Barlow-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Medium.woff") format("woff"), url("../fonts/Barlow-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-SemiBold.woff") format("woff"), url("../fonts/Barlow-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Bold.woff") format("woff"), url("../fonts/Barlow-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-ExtraBold.woff") format("woff"), url("../fonts/Barlow-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Black.woff") format("woff"), url("../fonts/Barlow-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

[class^="ico_"],
[class*=" ico_"] {
  display: inline-flex;
  align-items: center;
  line-height: 1em;
}

html,
body {
  font-family: "Barlow";
  color: #555;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

@media (max-width: 767px) {
  html,
  body {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  html,
  body {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Barlow";
  font-weight: 700;
}

.section-title {
  position: relative;
  margin-bottom: 30px;
}

.section-title span {
  color: #888;
  font-size: 18px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  position: relative;
}

@media (max-width: 767px) {
  .section-title span {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .section-title span {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.section-title span:before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 30px;
  background-image: url(../img/svgico/logo-icon.svg);
  margin-right: 10px;
}

.section-title h3 {
  color: #222;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  margin: 0;
}

@media (max-width: 767px) {
  .section-title h3 {
    font-size: calc(20px + 34 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .section-title h3 {
    font-size: calc(20px + 20 * (100vw / 1920));
  }
}

.section-title.--center {
  text-align: center;
}

.section-title.--center span {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.section-title.--center span:before {
  margin-right: 0;
  margin-bottom: 15px;
}

a.more {
  display: inline-flex;
  align-items: center;
  color: #222;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  a.more {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  a.more {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}

a.more .uk-icon {
  color: #efb007;
}

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("eot"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.woff2") format("woff2"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg");
}

.ico_arrow-down:before, .ico_arrow-left:before, .ico_arrow-right:before, .ico_arrow-up:before, .ico_checkmark:before, .ico_close:before, .ico_contacts:before, .ico_copy:before, .ico_edit:before, .ico_logo-icon:before, .ico_mission:before, .ico_submenu:before, .ico_track:before, .ico_trash:before, .ico_truck-plow:before, .ico_user:before, .slider-nav .swiper-button-prev:before, .slider-nav .swiper-button-next:before, .contacts-block:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  line-height: 1em;
}

.ico_arrow-down:before {
  content: "";
}

.ico_arrow-left:before {
  content: "";
}

.ico_arrow-right:before {
  content: "";
}

.ico_arrow-up:before {
  content: "";
}

.ico_checkmark:before {
  content: "";
}

.ico_close:before {
  content: "";
}

.ico_contacts:before {
  content: "";
}

.ico_copy:before {
  content: "";
}

.ico_edit:before {
  content: "";
}

.ico_logo-icon:before {
  content: "";
}

.ico_mission:before {
  content: "";
}

.ico_submenu:before {
  content: "";
}

.ico_track:before {
  content: "";
}

.ico_trash:before {
  content: "";
}

.ico_truck-plow:before {
  content: "";
}

.ico_user:before {
  content: "";
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus,
*:active,
*.nice-select.open {
  outline: none;
}

a:focus,
a:active,
a.nice-select.open {
  outline: none;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

body {
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-size: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  background-color: inherit;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.tooltip::after {
  content: attr(data-tooltip);
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

._video {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}

._video video,
._video iframe,
._video object,
._video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videobg video,
.videobg iframe,
.videobg object,
.videobg embed {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}

@font-face {
  font-family: 'footable';
  src: url("../fonts/footable.eot");
  src: url("../fonts/footable.eot") format("embedded-opentype"), url("../fonts/footable.woff2") format("woff2"), url("../fonts/footable.woff") format("woff"), url("../fonts/footable.ttf") format("truetype"), url("../fonts/footable.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fooicon {
  display: inline-block;
  font-size: inherit;
  font-family: footable !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0);
}

.fooicon-loader:before {
  content: '\e800';
}

.fooicon-plus:before {
  content: '\e801';
}

.fooicon-minus:before {
  content: '\e802';
}

.fooicon-search:before {
  content: '\e803';
}

.fooicon-remove:before {
  content: '\e804';
}

.fooicon-pencil:before {
  content: '\e805';
}

.fooicon-flash:before {
  content: '\e806';
}

.fooicon-eye-close:before {
  content: '\e807';
}

.fooicon-cog:before {
  content: '\e808';
}

.fooicon-stats:before {
  content: '\e809';
}

.fooicon-sort:before {
  content: '\f0dc';
}

.fooicon-sort-asc:before {
  content: '\f160';
}

.fooicon-sort-desc:before {
  content: '\f161';
}

.fooicon-trash:before {
  content: '\f1f8';
}

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 38px;
  line-height: 36px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #000;
}

.nice-select:after {
  width: 7px;
  height: 7px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: '';
  display: block;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .list {
  min-width: 100%;
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #000;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #333333;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 30px;
  line-height: 28px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 28px;
  min-height: 28px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-19px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 36px;
  list-style: none;
  min-height: 36px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #000;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

.page-wrapper {
  min-width: 320px;
  min-height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.page-wrapper .page-main {
  flex-grow: 1;
}

.page-head {
  display: block;
  position: relative;
}

.page-head__bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px;
  height: 300px;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .page-head__bg {
    height: 150px;
  }
}

.page-head__title {
  color: white;
  font-size: 48px;
  font-weight: 700;
  line-height: 66px;
}

@media (max-width: 767px) {
  .page-head__title {
    font-size: calc(24px + 40.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .page-head__title {
    font-size: calc(24px + 24 * (100vw / 1920));
  }
}

.page-head__breadcrumb {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #efb007;
  border-radius: 2px;
  padding: 20px 35px;
  color: #fff;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .page-head__breadcrumb {
    padding: 10px 15px;
    width: 90%;
  }
}

.page-head__breadcrumb .uk-breadcrumb {
  margin: 0;
}

.page-head__breadcrumb .uk-breadcrumb > * > * {
  color: #fff;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .page-head__breadcrumb .uk-breadcrumb > * > * {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .page-head__breadcrumb .uk-breadcrumb > * > * {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}

.page-head__breadcrumb .uk-breadcrumb > :last-child > span {
  color: #fff;
}

.page-head__breadcrumb .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column):before {
  content: '→';
  color: #fff;
  margin: 0 10px 0 6px;
}

.uk-pagination > * + * {
  margin-left: 10px;
}

.uk-pagination > * > * {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 55px;
  height: 55px;
  border: 1px solid #eee;
  border-radius: 2px;
  color: #222;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.uk-pagination > .uk-active > * {
  background: #222;
  color: #fff;
}

.sidebar {
  display: block;
  position: relative;
  padding: 0 0 0 40px;
  background-color: #f9f9f9;
  height: 100%;
}

@media only screen and (max-width: 1200px) {
  .sidebar {
    padding: 0 0 0 20px;
  }
}

@media only screen and (max-width: 959px) {
  .sidebar {
    background: none;
    padding: 0;
  }
}

.sidebar::after {
  content: '';
  display: block;
  position: absolute;
  top: -70px;
  bottom: -70px;
  left: 0;
  width: 50vw;
  height: calc(100% + 140px);
  background-color: #f9f9f9;
  z-index: -1;
}

@media only screen and (max-width: 959px) {
  .sidebar::after {
    display: none;
  }
}

.sidebar.--left {
  padding: 0 40px 0 0;
}

@media only screen and (max-width: 1199px) {
  .sidebar.--left {
    padding: 0;
  }
}

.sidebar.--left:after {
  left: auto;
  right: 0;
}

.scroll-box {
  scrollbar-color: #efb007 #E2EBEC;
  scrollbar-width: thin !important;
  -moz-appearance: none !important;
}

.scroll-box::-webkit-scrollbar {
  width: 4px;
}

.scroll-box::-webkit-scrollbar-track {
  background-color: #E2EBEC;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #efb007;
}

.datepicker--day-name,
.datepicker--cell.-current- {
  color: #efb007;
}

.datepicker--cell.-selected- {
  background-color: #efb007;
}

.uk-button {
  border-radius: 2px;
  transition: all 0.3s linear;
}

.uk-button > svg {
  margin-left: 15px;
}

.uk-button .uk-icon {
  color: #efb007;
  margin-left: 15px;
}

.uk-button + .uk-button {
  margin-left: 10px;
}

.uk-button-default {
  border-color: #222;
  border-radius: 2px;
  color: #222;
  font-weight: 700;
}

.uk-button-default:hover {
  background-color: #efb007;
  border-color: #efb007;
  color: #fff;
}

.uk-button-default:hover .uk-icon {
  color: #fff;
}

.uk-button-danger {
  background-color: #efb007;
}

.uk-button-danger:hover, .uk-button-danger:focus, .uk-button-danger:active, .uk-button-danger.nice-select.open {
  background-color: #d69e06;
}

.uk-button-danger .uk-icon {
  color: #fff;
}

.uk-button-light {
  background-color: #fff;
  color: #222 !important;
  font-weight: 700;
}

.uk-button-large {
  font-size: 18px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .uk-button-large {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .uk-button-large {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

@media only screen and (max-width: 767px) {
  .uk-button-large {
    height: 45px;
    line-height: 43px;
    padding: 0 20px;
  }
}

.uk-lightbox {
  background-color: rgba(34, 34, 34, 0.8);
}

.slider-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.slider-nav .swiper-button-prev,
.slider-nav .swiper-button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-top: 0;
}

.slider-nav .swiper-button-prev::before,
.slider-nav .swiper-button-next::before {
  color: #ccc;
}

.slider-nav .swiper-button-prev:after,
.slider-nav .swiper-button-next:after {
  display: none;
}

.slider-nav .swiper-button-prev:hover, .slider-nav .swiper-button-prev:focus, .slider-nav .swiper-button-prev:active, .slider-nav .swiper-button-prev.nice-select.open,
.slider-nav .swiper-button-next:hover,
.slider-nav .swiper-button-next:focus,
.slider-nav .swiper-button-next:active,
.slider-nav .swiper-button-next.nice-select.open {
  color: #efb007;
  border-color: #efb007;
}

.slider-nav .swiper-button-prev:hover::before, .slider-nav .swiper-button-prev:focus::before, .slider-nav .swiper-button-prev:active::before, .slider-nav .swiper-button-prev.nice-select.open::before,
.slider-nav .swiper-button-next:hover::before,
.slider-nav .swiper-button-next:focus::before,
.slider-nav .swiper-button-next:active::before,
.slider-nav .swiper-button-next.nice-select.open::before {
  color: #efb007;
}

.slider-nav .swiper-button-prev:before {
  content: "";
}

.slider-nav .swiper-button-next {
  margin-left: 20px;
}

.slider-nav .swiper-button-next:before {
  content: "";
}

.swiper-pagination-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-horizontal .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #ddd;
  border-radius: 4px;
  opacity: 1;
}

.swiper-pagination-horizontal .swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  background-color: #efb007;
  border-radius: 6px;
}

.uk-input,
.uk-textarea {
  background-color: #f7f7f7;
  border: none;
  border-radius: 2px;
  color: #222;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .uk-input,
  .uk-textarea {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .uk-input,
  .uk-textarea {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.uk-input::placeholder,
.uk-textarea::placeholder {
  color: #888;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .uk-input::placeholder,
  .uk-textarea::placeholder {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .uk-input::placeholder,
  .uk-textarea::placeholder {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.uk-input:focus,
.uk-textarea:focus {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
}

.uk-textarea {
  resize: none;
  height: 150px;
}

@media only screen and (max-width: 767px) {
  .uk-textarea {
    height: 100px;
  }
}

.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 62px;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .uk-form-large:not(textarea):not([multiple]):not([size]) {
    padding-left: 15px;
    height: 45px;
  }
}

textarea.uk-form-large {
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  textarea.uk-form-large {
    padding-left: 15px;
  }
}

.uk-position-cover {
  background-color: rgba(0, 0, 0, 0.65);
}

.uk-dotnav {
  align-items: center;
}

.uk-dotnav > * > * {
  width: 8px;
  height: 8px;
  background-color: #ddd;
  border: none;
}

.uk-dotnav > .uk-active > * {
  background-color: #efb007;
  width: 10px;
  height: 10px;
}

.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.page-header {
  display: block;
  position: relative;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 100px;
}

@media only screen and (max-width: 1799px) {
  .page-header {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 959px) {
  .page-header {
    padding: 0 0 0 30px;
  }
}

@media only screen and (max-width: 639px) {
  .page-header {
    padding: 0 0 0 15px;
  }
}

.page-header.uk-active {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.page-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header__contacts-list {
  margin-right: auto;
}

@media only screen and (max-width: 1023px) {
  .page-header__contacts-list {
    display: none;
  }
}

.page-header__contacts-list ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.page-header__contacts-list ul li {
  display: block;
  position: relative;
}

.page-header__contacts-list ul li + li {
  margin-left: 20px;
}

.page-header__contacts-list ul li a {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

@media (max-width: 767px) {
  .page-header__contacts-list ul li a {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .page-header__contacts-list ul li a {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.page-header__contacts-list ul li a .uk-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

@media only screen and (max-width: 1365px) {
  .page-header__contacts-list ul li a .uk-icon {
    width: 30px;
    height: 30px;
  }
}

.page-header__logo {
  margin-right: auto;
  padding-top: 22px;
  padding-bottom: 22px;
}

@media only screen and (max-width: 1365px) {
  .page-header__logo {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.page-header__mainmenu {
  margin-left: 20px;
}

@media only screen and (max-width: 959px) {
  .page-header__mainmenu {
    display: none;
  }
}

.page-header__mainmenu .uk-navbar-nav > li > a {
  color: #222;
  font-family: Barlow;
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
  min-height: 50px;
  transition: all 0.3 linear;
}

@media (max-width: 767px) {
  .page-header__mainmenu .uk-navbar-nav > li > a {
    font-size: calc(12pxpx + 13.6px * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .page-header__mainmenu .uk-navbar-nav > li > a {
    font-size: calc(12pxpx + 8px * (100vw / 1920));
  }
}

@media only screen and (max-width: 1365px) {
  .page-header__mainmenu .uk-navbar-nav > li > a {
    padding: 0 10px;
    font-size: 16px;
  }
}

.page-header__mainmenu .uk-navbar-nav > li > a:hover {
  color: #efb007;
}

.page-header__mainmenu .uk-navbar-nav > li > a::before {
  content: '[';
  display: none;
  position: relative;
}

.page-header__mainmenu .uk-navbar-nav > li > a::after {
  content: ']';
  display: none;
  position: relative;
}

.page-header__mainmenu .uk-navbar-nav > li > a span {
  display: block;
  position: relative;
}

.page-header__mainmenu .uk-navbar-nav > li > a span::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='10.5,16.08 5.63,10.66 6.37,10 10.5,14.58 14.63,10 15.37,10.66'%3E%3C/polygon%3E%3Cline fill='none' stroke='%23000' x1='10.5' y1='4' x2='10.5' y2='15'%3E%3C/line%3E%3C/svg%3E");
  opacity: 0;
}

.page-header__mainmenu .uk-navbar-nav > li.uk-active > a {
  color: #efb007;
}

.page-header__mainmenu .uk-navbar-nav > li.uk-active > a:before, .page-header__mainmenu .uk-navbar-nav > li.uk-active > a:after {
  display: inline-block;
}

.page-header__mainmenu .uk-navbar-nav > li.uk-active > a span::before {
  opacity: 1;
}

.page-header__mainmenu .uk-navbar-nav .uk-navbar-dropdown {
  width: 250px;
}

.page-header__search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

@media only screen and (max-width: 1365px) {
  .page-header__search {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 959px) {
  .page-header__search {
    border-left: 1px solid #eee;
  }
}

.page-header__search .uk-search-icon svg {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 959px) {
  .page-header__user .uk-icon-button {
    border-left: 1px solid #eee;
  }
}

.page-header__contacts {
  padding-left: 50px;
  border-left: 1px solid #eee;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 1365px) {
  .page-header__contacts {
    padding-left: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 959px) {
  .page-header__contacts {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 639px) {
  .page-header__contacts {
    padding: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.page-header__mobile-menu-btn {
  display: none;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

@media only screen and (max-width: 959px) {
  .page-header__mobile-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
  }
}

.page-header__mobile-menu-btn button {
  background: none;
  border: none;
}

.page-header__mobile-menu-btn button svg {
  width: 30px;
  height: 30px;
}

.logo {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.logo img {
  display: block;
  position: relative;
}

@media only screen and (max-width: 1365px) {
  .logo img {
    max-height: 30px;
  }
}

.logo span {
  display: inline-block;
  position: relative;
  color: #555;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-left: 15px;
  transform: translateY(2px);
}

@media only screen and (max-width: 1679px) {
  .logo span {
    display: none;
  }
}

.contacts-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding-left: 70px;
}

@media only screen and (max-width: 1365px) {
  .contacts-block {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 639px) {
  .contacts-block {
    padding-left: 30px;
  }
}

.contacts-block:before {
  content: "";
}

.contacts-block::before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -25px;
  font-size: 50px;
  color: #efb007;
}

@media only screen and (max-width: 1365px) {
  .contacts-block::before {
    font-size: 30px;
    margin-top: -15px;
  }
}

.contacts-block span {
  color: #222;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1365px) {
  .contacts-block span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 639px) {
  .contacts-block span {
    display: none;
  }
}

.contacts-block strong {
  color: #222;
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
}

@media only screen and (max-width: 1365px) {
  .contacts-block strong {
    font-size: 16px;
  }
}

@media only screen and (max-width: 639px) {
  .contacts-block strong {
    display: none;
  }
}

.page-header.--two-line {
  padding: 0;
}

.page-header.--two-line .page-header__top-line {
  background-color: #222;
  padding: 12px 0;
}

@media only screen and (max-width: 639px) {
  .page-header.--two-line .page-header__top-line {
    display: none;
  }
}

.page-header.--two-line .page-header__bottom-line {
  padding-top: 12px;
  padding-bottom: 12px;
  background: #fff;
}

@media only screen and (max-width: 959px) {
  .page-header.--two-line .page-header__bottom-line {
    padding-top: 8px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 639px) {
  .page-header.--two-line .page-header__bottom-line {
    padding-top: 0;
  }
}

.page-header.--two-line .page-header__bottom-line.uk-active {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.page-header.--two-line .page-header__bottom-line.uk-active .page-header__logo {
  padding-top: 0;
  padding-bottom: 0;
}

.page-header.--two-line .page-header__social {
  margin-right: 30px;
}

.page-header.--two-line .page-header__social .social__link {
  background-color: transparent;
}

.page-header.--two-line .page-header__social .social__link:hover {
  background-color: #efb007;
}

.page-header.--two-line .page-header__contacts {
  display: block;
  position: relative;
  border: none;
  background-color: #efb007;
  margin-top: -12px;
  margin-bottom: -20px;
  padding: 12px 30px;
}

.page-header.--two-line .page-header__contacts:before, .page-header.--two-line .page-header__contacts:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
}

.page-header.--two-line .page-header__contacts::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 0;
  border-color: transparent #966f0c transparent transparent;
  left: -8px;
}

.page-header.--two-line .page-header__contacts:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 0;
  border-color: #966f0c transparent transparent transparent;
  right: -8px;
}

.page-header.--two-line .contacts-block {
  padding-left: 50px;
  background-image: url(../img/ico-info-1.png);
  background-size: 40px;
  background-repeat: no-repeat;
}

.page-header.--two-line .contacts-block::before {
  display: none;
}

.page-header.--two-line .contacts-block span {
  color: #fff;
  font-size: 14px;
}

.page-header.--two-line .contacts-block strong {
  color: #fff;
  font-size: 22px;
}

.page-header.--two-line .uk-icon-button {
  width: 55px;
  height: 55px;
}

@media only screen and (max-width: 959px) {
  .page-header.--two-line .uk-icon-button {
    background: none;
    width: 60px;
    height: 60px;
    border-radius: 0;
  }
}

.page-header.--two-line .page-header__mainmenu {
  margin: 0 auto;
}

.newsletter {
  display: block;
  position: relative;
  background-color: #efb007;
  background-image: url(../img/bg_newsletter.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.newsletter__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1359px) {
  .newsletter__inner {
    flex-direction: column;
    align-items: stretch;
  }
}

.newsletter-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding-right: 70px;
  padding-right: 3.64583vw;
  margin-right: 70px;
  margin-right: 3.64583vw;
  border-right: 1px solid #fff;
}

@media only screen and (max-width: 1439px) {
  .newsletter-form {
    padding-right: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1359px) {
  .newsletter-form {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
  }
}

@media only screen and (max-width: 639px) {
  .newsletter-form {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 639px) {
  .newsletter-form__title {
    margin-bottom: 10px;
  }
}

.newsletter-form__title h4 {
  color: white;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 15px 0 0;
}

@media (max-width: 767px) {
  .newsletter-form__title h4 {
    font-size: calc(18px + 30.6 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .newsletter-form__title h4 {
    font-size: calc(18px + 18 * (100vw / 1920));
  }
}

@media only screen and (max-width: 639px) {
  .newsletter-form__title h4 br {
    display: none;
  }
}

.newsletter-form__form {
  width: 100%;
  border-radius: 2px;
  border: 10px solid rgba(255, 255, 255, 0.2);
  border: 0.52083vw solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 1199px) {
  .newsletter-form__form {
    border: 5px solid rgba(255, 255, 255, 0.2);
  }
}

.newsletter-form__form form {
  background-color: #fff;
  padding: 15px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .newsletter-form__form form {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 639px) {
  .newsletter-form__form form {
    padding: 5px 5px 5px 10px;
  }
}

.newsletter-form__form form input {
  flex-grow: 1;
  border: none;
  width: 100%;
  height: 40px;
  padding-left: 35px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23000' points='1.4,6.5 10,11 18.6,6.5'%3E%3C/polyline%3E%3Cpath d='M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left center;
}

.newsletter-form__form form button {
  border: none;
  background: none;
  color: #222;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  border-left: 1px solid #ddd;
}

@media (max-width: 767px) {
  .newsletter-form__form form button {
    font-size: calc(12px + 13.6 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .newsletter-form__form form button {
    font-size: calc(12px + 8 * (100vw / 1920));
  }
}

@media only screen and (max-width: 639px) {
  .newsletter-form__form form button {
    padding: 0 10px;
  }
}

.download-app {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-app__title {
  margin-right: 30px;
}

@media only screen and (max-width: 639px) {
  .download-app__title {
    margin-right: 10px;
  }
}

.download-app__title h4 {
  color: white;
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 10px 0;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .download-app__title h4 {
    font-size: calc(16px + 34 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .download-app__title h4 {
    font-size: calc(16px + 20 * (100vw / 1920));
  }
}

@media only screen and (max-width: 639px) {
  .download-app__title h4 {
    margin-bottom: 5px;
  }
}

.download-app__title p {
  color: white;
  font-size: 20px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .download-app__title p {
    font-size: calc(12px + 13.6 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .download-app__title p {
    font-size: calc(12px + 8 * (100vw / 1920));
  }
}

.download-app__links {
  display: flex;
  align-items: center;
}

.download-link {
  border-radius: 1px;
  outline: 10px solid rgba(255, 255, 255, 0.2);
  outline: 0.52083vw solid rgba(255, 255, 255, 0.2);
  background-color: #fff;
  padding: 15px 35px;
  padding: 15px 1.82292vw;
}

@media only screen and (max-width: 1199px) {
  .download-link {
    outline: 5px solid rgba(255, 255, 255, 0.2);
    padding: 10px;
  }
}

.download-link + .download-link {
  margin-left: 30px;
}

@media only screen and (max-width: 639px) {
  .download-link + .download-link {
    margin-left: 10px;
  }
}

.download-link img {
  min-width: 40px;
}

@media only screen and (max-width: 1199px) {
  .download-link img {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }
}

.page-footer {
  display: block;
  position: relative;
  background-color: #111;
  z-index: 1;
}

.page-footer__top {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 1199px) {
  .page-footer__top {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.page-footer__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 1199px) {
  .page-footer__inner {
    flex-wrap: wrap;
  }
}

.page-footer__contacts {
  padding: 50px;
  background-color: #222;
  border-radius: 4px;
  max-width: 390px;
  margin-top: -115px;
}

@media only screen and (max-width: 1359px) {
  .page-footer__contacts {
    padding: 50px 30px;
    max-width: 350px;
  }
}

@media only screen and (max-width: 1199px) {
  .page-footer__contacts {
    order: 1;
    width: 50%;
    max-width: calc(50% - 20px);
    margin-bottom: 40px;
    padding: 30px;
    margin-top: -65px;
  }
}

@media only screen and (max-width: 767px) {
  .page-footer__contacts {
    order: 1;
    width: 100%;
    max-width: 100%;
  }
}

.page-footer__contacts .footer-logo {
  margin-bottom: 35px;
}

.page-footer__contacts p {
  color: rgba(231, 231, 231, 0.7);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}

@media only screen and (max-width: 1199px) {
  .page-footer__contacts p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1199px) {
  .page-footer__menu {
    order: 3;
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .page-footer__menu {
    order: 2;
    width: 100%;
    margin-bottom: 30px;
  }
}

.page-footer__menu h4 {
  color: white;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .page-footer__menu h4 {
    font-size: calc(18px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .page-footer__menu h4 {
    font-size: calc(18px + 4 * (100vw / 1920));
  }
}

.page-footer__menu h4:after {
  content: '';
  display: block;
  width: 44px;
  height: 2px;
  background-color: #efb007;
  margin-top: 10px;
}

.page-footer__menu ul li + li {
  display: block;
  position: relative;
  margin-top: 25px;
}

.page-footer__menu ul li a {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
  padding: 0;
}

@media (max-width: 767px) {
  .page-footer__menu ul li a {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .page-footer__menu ul li a {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.page-footer__menu ul li a::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23fff' points='10 5 15 9.5 10 14'%3E%3C/polyline%3E%3Cline fill='none' stroke='%23fff' x1='4' y1='9.5' x2='15' y2='9.5'%3E%3C/line%3E%3C/svg%3E");
  margin-right: 5px;
}

.page-footer__menu ul li a:hover::before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23efb007' points='10 5 15 9.5 10 14'%3E%3C/polyline%3E%3Cline fill='none' stroke='%23efb007' x1='4' y1='9.5' x2='15' y2='9.5'%3E%3C/line%3E%3C/svg%3E");
}

@media only screen and (max-width: 1199px) {
  .page-footer__news {
    order: 2;
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .page-footer__news {
    order: 3;
    width: 100%;
  }
}

.page-footer__news h4 {
  color: white;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .page-footer__news h4 {
    font-size: calc(18px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .page-footer__news h4 {
    font-size: calc(18px + 4 * (100vw / 1920));
  }
}

.page-footer__news h4:after {
  content: '';
  display: block;
  width: 44px;
  height: 2px;
  background-color: #efb007;
  margin-top: 10px;
}

.page-footer__bottom {
  padding-top: 45px;
  padding-bottom: 45px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

@media only screen and (max-width: 1199px) {
  .page-footer__bottom {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.page-footer__copy {
  text-align: center;
  color: white;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}

@media only screen and (max-width: 1199px) {
  .page-footer__copy {
    font-size: 14px;
  }
}

@media only screen and (max-width: 639px) {
  .page-footer__copy {
    font-size: 12px;
  }
}

.page-footer__copy span {
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (max-width: 639px) {
  .page-footer__copy span {
    margin-right: 0;
    display: block;
    margin-bottom: 10px;
  }
}

.page-footer__copy a {
  color: rgba(255, 255, 255, 0.3);
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-decoration: underline;
  transition: all 0.3s linear;
}

.page-footer__copy a:hover {
  color: #efb007;
}

@media only screen and (max-width: 1199px) {
  .page-footer__copy a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 639px) {
  .page-footer__copy a {
    font-size: 10px;
  }
}

.page-footer__copy a + a {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.contacts-list {
  list-style-type: none;
  padding: 0;
  margin: 30px 0;
}

.contacts-list li {
  display: block;
  position: relative;
}

.contacts-list li a {
  display: flex;
  align-items: baseline;
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

@media only screen and (max-width: 1199px) {
  .contacts-list li a {
    font-size: 16px;
  }
}

.contacts-list li + li {
  margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
  .contacts-list li + li {
    margin-top: 10px;
  }
}

.contacts-list li span.label {
  display: block;
  color: #efb007;
}

.contacts-list li span.uk-icon {
  margin-right: 15px;
}

@media only screen and (max-width: 1199px) {
  .contacts-list li span.uk-icon {
    margin-right: 10px;
  }
}

.social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  transition: all 0.3s linear;
}

.social li a:hover {
  background-color: #efb007;
}

.social li + li {
  margin-left: 5px;
}

.news-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.news-list li + li {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.news-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 300px;
}

.news-list-item__img {
  width: 95px;
  height: 88px;
  margin-right: 20px;
}

.news-list-item__info {
  display: flex;
  flex-direction: column;
}

.news-list-item__date {
  color: #efb007;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.news-list-item__title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  transition: all 0.3 linear;
}

.news-list-item__title:hover {
  color: #efb007;
}

@media (max-width: 767px) {
  .news-list-item__title {
    font-size: calc(16px + 3.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .news-list-item__title {
    font-size: calc(16px + 2 * (100vw / 1920));
  }
}

.page-header__mainmenu .uk-navbar-dropdown .uk-navbar-dropdown {
  left: 60% !important;
}

.uk-offcanvas-bar {
  background-color: #111;
}

.uk-offcanvas-bar .uk-offcanvas-close {
  right: 20px;
  color: #efb007;
  opacity: 1;
}

.uk-offcanvas-bar .contacts-block {
  padding-left: 50px;
}

.uk-offcanvas-bar .contacts-block::before {
  font-size: 40px;
  margin-top: -20px;
}

.uk-offcanvas-bar .contacts-block span, .uk-offcanvas-bar .contacts-block strong {
  display: block !important;
  color: #fff;
}

.uk-offcanvas-bar .uk-nav-default > li > a {
  color: #fff;
  font-size: 18px;
}

.uk-offcanvas-bar .uk-nav-default .uk-nav-sub a {
  color: #fff;
  font-size: 16px;
}

.uk-offcanvas-bar .uk-nav-parent-icon > .uk-parent > a:after {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Crect fill='%23fff' x='9' y='1' width='1' height='17'%3E%3C/rect%3E%3Crect fill='%23fff' x='1' y='9' width='17' height='1'%3E%3C/rect%3E%3C/svg%3E");
}

.uk-offcanvas-bar .uk-nav-parent-icon > .uk-parent.uk-open > a:after {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Crect fill='%23fff' height='1' width='18' y='9' x='1'%3E%3C/rect%3E%3C/svg%3E");
}

.action-group {
  display: inline-block !important;
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
}

.action-group.is-show .action-group-drop {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.action-group-btn {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  border-radius: 50%;
  color: #B3B3B3;
  cursor: pointer;
  font-size: 10px;
  background-color: #F0F1F4;
  transition: all 0.3s linear;
}

.action-group-btn:hover {
  background-color: #929DA5;
  color: #fff;
}

.action-group-drop {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  display: block;
  visibility: hidden;
  z-index: 3;
  position: absolute;
  top: -13px;
  right: -13px;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  background: #FFFFFF;
  border: 1.5px solid #E1E3EA;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 15px 30px -20px rgba(146, 157, 165, 0.2);
  border-radius: 30px 0px 30px 30px;
}

.action-group-list {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 10px 5px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  list-style-type: none;
}

.action-group-list li {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  transition: all 0.3s linear;
  margin: 0 5px;
}

.action-group-list li.is-active button {
  background-color: #929DA5;
  color: #fff;
}

.action-group-list li button,
.action-group-list li a {
  width: 35px;
  height: 35px;
  flex: 0 0 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 0;
  background: none;
  color: #929DA5 !important;
  pointer-events: all;
  cursor: pointer;
  background: #F0F1F4;
  font-size: 20px;
  border-radius: 50%;
  transition: all 0.3s linear;
}

.action-group-list li button:hover,
.action-group-list li a:hover {
  color: #efb007 !important;
}

.action-group-list li button .ico_edit,
.action-group-list li a .ico_edit {
  font-size: 15px;
}

.action-group-list li button .ico_close,
.action-group-list li a .ico_close {
  font-size: 16px;
}

.action-group-list li:last-child button {
  background-color: #fff;
}

.action-group-list li:last-child button:hover {
  color: #efb007 !important;
}

.counter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 125px;
  flex: 0 0 125px;
  padding: 0 40px 0 0;
  overflow: hidden;
  box-sizing: border-box;
  user-select: none;
  background-color: #fff;
  border: 2px solid #E1E3EA;
  border-radius: 20px;
}

.counter__field {
  -webkit-box-shadow: none;
  width: 40px;
  border: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  user-select: none;
}

.counter__field input {
  -moz-appearance: textfield;
  position: relative;
  max-width: 40px;
  height: 40px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 130%;
  color: #050505;
  text-align: center;
  user-select: none;
}

.counter__field input:hover, .counter__field input:focus, .counter__field input:active, .counter__field input.nice-select.open {
  outline: 0;
}

.counter__field input[type=number]::-webkit-inner-spin-button, .counter__field input[type=number]::-webkit-outer-spin-buttons {
  -webkit-appearance: none;
  margin: 0;
}

.counter__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  overflow: hidden;
  border: none;
  box-shadow: none;
  user-select: none;
  color: #fff;
  cursor: pointer;
  margin: 5px;
  border-radius: 50%;
}

.counter__btn:hover {
  background: #F0F1F4;
}

.counter__btn:after {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  text-shadow: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  transition: all 0.3s linear;
}

.counter__btn.minus:after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.583557 1.5351C0.583557 0.98281 1.03127 0.535095 1.58356 0.535095H7.58356C8.13584 0.535095 8.58356 0.98281 8.58356 1.5351C8.58356 2.08738 8.13584 2.5351 7.58356 2.5351H1.58356C1.03127 2.5351 0.583557 2.08738 0.583557 1.5351Z' fill='black'/%3E%3C/svg%3E%0A");
}

.counter__btn.plus:after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.583557 4.53511C0.583557 4.09328 0.941729 3.73511 1.38356 3.73511H7.78356C8.22539 3.73511 8.58356 4.09328 8.58356 4.53511C8.58356 4.97694 8.22539 5.33511 7.78356 5.33511H1.38356C0.941729 5.33511 0.583557 4.97694 0.583557 4.53511Z' fill='black'/%3E%3Cpath d='M4.58356 0.53511C5.02538 0.53511 5.38356 0.893283 5.38356 1.33511L5.38356 7.73511C5.38356 8.17694 5.02538 8.53511 4.58356 8.53511C4.14173 8.53511 3.78356 8.17694 3.78356 7.73511L3.78356 1.33511C3.78356 0.893282 4.14173 0.53511 4.58356 0.53511Z' fill='black'/%3E%3C/svg%3E%0A");
}

.drop {
  display: block;
  position: relative;
  z-index: 3;
}

.drop._active {
  z-index: 11;
}

.drop._active .drop-button {
  background-image: none;
}

.drop._active-close .drop-button {
  background-image: none;
}

.drop-box {
  display: none;
  position: absolute;
  top: calc( 100% + 5px);
  left: 0;
  box-sizing: border-box;
  background-color: #fff;
  padding: 15px 5px 15px 15px;
  border: 2px solid #D8DFE7;
  z-index: 3;
  width: 100%;
}

.drop-input {
  height: 50px;
  line-height: 50px;
  padding: 0 50px 0 15px;
  transition: all 0.3s linear;
}

.drop-input:hover, .drop-input:focus, .drop-input:active, .drop-input.nice-select.open {
  box-shadow: none;
}

.drop-input:focus {
  transition: all 0.3s linear;
}

.drop-input:focus::placeholder {
  opacity: 0;
}

.drop-input-hide {
  display: none;
}

.drop-button {
  text-align: left;
  color: #7A7A7A;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.99999 4.31654L1.67513 0.991678C1.48622 0.802774 1.18058 0.802774 0.991678 0.991678C0.802774 1.18058 0.802774 1.48622 0.991678 1.67513L4.65827 5.34172C4.75246 5.43591 4.87641 5.48332 5 5.48332C5.1236 5.48332 5.24755 5.43591 5.34174 5.34172L9.00833 1.67513L9.00834 1.67512C9.19722 1.48622 9.19722 1.18058 9.00831 0.991677C8.81941 0.802773 8.51377 0.802773 8.32486 0.991677L4.99999 4.31654Z' fill='%2357CA70' stroke='%2357CA70' stroke-width='0.3'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 18px center;
  background-size: 10px;
  padding: 11.5px 15px;
}

.drop-clear {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #efb007;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2357CA70'%3E%3Cpath d='M14,12l9.1-9.1c0.6-0.5,0.6-1.4,0-2c-0.5-0.6-1.4-0.6-2,0L12,10L2.9,0.9c-0.3-0.3-0.6-0.4-1-0.4 c-0.4,0-0.7,0.2-1,0.4c-0.5,0.6-0.5,1.4,0,2L10,12l-9.1,9.1c-0.6,0.6-0.6,1.5,0,2c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4L12,14 l9.1,9.1c0.6,0.5,1.5,0.5,2,0c0.5-0.6,0.5-1.4,0-2L14,12z'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.drop-clear._active {
  display: flex;
}

.drop-clear._active + .form-control {
  background: none;
}

.drop-overflow {
  max-height: 90px;
  overflow: auto;
  scrollbar-color: #efb007 #E2EBEC;
  scrollbar-width: thin !important;
  -moz-appearance: none !important;
}

.drop-overflow::-webkit-scrollbar {
  width: 4px;
}

.drop-overflow::-webkit-scrollbar-track {
  background-color: #E2EBEC;
}

.drop-overflow::-webkit-scrollbar-thumb {
  background-color: #efb007;
}

.drop .drop-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.drop .drop-list-item {
  display: block;
  position: relative;
  padding: 5px 0;
  font-size: 14px;
  display: block;
  position: relative;
  color: #000;
  cursor: pointer;
  transition: all 0.3s linear;
}

.drop .drop-list-item a {
  display: block;
  font-size: 14px;
  display: block;
  position: relative;
  color: #000;
  transition: all 0.3s linear;
}

.drop .drop-list-item a:hover {
  color: #efb007;
  text-decoration: none;
}

.--arrow .drop-input {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.99999 4.31654L1.67513 0.991678C1.48622 0.802774 1.18058 0.802774 0.991678 0.991678C0.802774 1.18058 0.802774 1.48622 0.991678 1.67513L4.65827 5.34172C4.75246 5.43591 4.87641 5.48332 5 5.48332C5.1236 5.48332 5.24755 5.43591 5.34174 5.34172L9.00833 1.67513L9.00834 1.67512C9.19722 1.48622 9.19722 1.18058 9.00831 0.991677C8.81941 0.802773 8.51377 0.802773 8.32486 0.991677L4.99999 4.31654Z' fill='%2357CA70' stroke='%2357CA70' stroke-width='0.3'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 18px center;
  background-size: 10px;
}

.--arrow._active .drop-input, .--arrow._active-close .drop-input {
  background-image: none;
}

.--search .drop-input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 512 512' xml:space='preserve' fill='%2357CA70'%3E%3Cpath d='M225.474,0C101.151,0,0,101.151,0,225.474c0,124.33,101.151,225.474,225.474,225.474 c124.33,0,225.474-101.144,225.474-225.474C450.948,101.151,349.804,0,225.474,0z M225.474,409.323 c-101.373,0-183.848-82.475-183.848-183.848S124.101,41.626,225.474,41.626s183.848,82.475,183.848,183.848 S326.847,409.323,225.474,409.323z'/%3E%3Cpath d='M505.902,476.472L386.574,357.144c-8.131-8.131-21.299-8.131-29.43,0c-8.131,8.124-8.131,21.306,0,29.43l119.328,119.328 c4.065,4.065,9.387,6.098,14.715,6.098c5.321,0,10.649-2.033,14.715-6.098C514.033,497.778,514.033,484.596,505.902,476.472z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-size: 20px;
}

.--search._active .drop-input, .--search._active._active-close {
  background-image: none;
}

.drop-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .drop-menu {
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    position: relative;
  }
}

.drop-menu.open li {
  display: block;
}

.drop-menu.open li:first-child::after {
  transform: rotate(180deg);
}

.drop-menu li {
  margin: 0 15px;
}

@media only screen and (max-width: 1199px) {
  .drop-menu li {
    margin: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .drop-menu li {
    margin: 0;
    display: none;
    position: relative;
    cursor: pointer;
    padding: 20px 10px;
    width: 100%;
  }
  .drop-menu li:first-child {
    display: inline-flex;
    align-items: center;
    width: auto;
    border-top: 0px;
  }
  .drop-menu li:first-child::after {
    content: '';
    display: block;
    position: relative;
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Vector 25' d='M1 1L6 6L11 1' stroke='%2330393E' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 10px;
    transition: all 0.3s linear;
  }
  .drop-menu li:not(:first-child) {
    background-color: #fff;
  }
  .drop-menu li:not(:first-child)::after {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    width: calc( 100% - 30px);
    height: 1px;
    background-color: #E4E6EA;
  }
}

.dropdown-sort {
  display: block;
  position: relative;
  margin-right: 5px;
}

@media only screen and (max-width: 767px) {
  .dropdown-sort {
    margin-right: 0;
  }
}

.dropdown-sort.show .dropdown-toggle {
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
}

.dropdown-sort .dropdown-toggle {
  width: auto;
  min-width: 220px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12' fill='none'%3E%3Cpath d='M0 7h12V5H0v2zm0-7v2h18V0H0zm0 12h6v-2H0v2z' fill='%2357CA70'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left 15px center;
  padding-right: 25px;
}

.dropdown-sort .dropdown-toggle:before {
  content: '';
  font-size: 8px;
  color: #57CA70;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: center;
  width: 10px;
  height: 6px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.99999 4.31654L1.67513 0.991678C1.48622 0.802774 1.18058 0.802774 0.991678 0.991678C0.802774 1.18058 0.802774 1.48622 0.991678 1.67513L4.65827 5.34172C4.75246 5.43591 4.87641 5.48332 5 5.48332C5.1236 5.48332 5.24755 5.43591 5.34174 5.34172L9.00833 1.67513L9.00834 1.67512C9.19722 1.48622 9.19722 1.18058 9.00831 0.991677C8.81941 0.802773 8.51377 0.802773 8.32486 0.991677L4.99999 4.31654Z' fill='%2357CA70' stroke='%2357CA70' stroke-width='0.3'/%3E%3C/svg%3E%0A");
  transition: all 0.3s linear;
}

.dropdown-sort .dropdown-toggle:after {
  display: none;
}

.dropdown-sort .dropdown-toggle[aria-expanded="true"]:before {
  transform: translateY(-50%) rotate(-180deg);
}

@media only screen and (max-width: 1199px) {
  .dropdown-sort .dropdown-toggle {
    height: 50px;
    border-radius: 5px;
  }
}

.dropdown-sort .dropdown-toggle span {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #30393E;
}

.dropdown-sort .dropdown-menu {
  width: 100%;
  border-top: 0;
  margin-top: 0;
  border-radius: 0 0 5px 5px;
  border: 1px solid #EFEFEF;
}

.dropdown-sort .dropdown-item {
  padding: 6px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: #30393E;
  cursor: pointer;
  transition: all 0.3s linear;
}

.dropdown-sort .dropdown-item._active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath d='M14 7A7 7 0 110 7a7 7 0 0114 0z' fill='%2357CA70'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 13A6 6 0 107 1a6 6 0 000 12zm0 1A7 7 0 107 0a7 7 0 000 14z' fill='%23fff'/%3E%3Cpath d='M6.68 10.115a.356.356 0 01-.505 0L4.157 8.097a.536.536 0 010-.758l.253-.253c.21-.21.548-.21.758 0l1.26 1.26L9.832 4.94a.537.537 0 01.758 0l.253.253c.21.21.21.55 0 .758L6.68 10.115z' fill='%23fff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-color: transparent;
  color: #efb007;
}

.dropdown-sort .dropdown-item:active, .dropdown-sort .dropdown-item.nice-select.open {
  background-color: transparent;
}

.dropdown-sort .dropdown-item:hover {
  background-color: transparent;
  color: #efb007;
}

.checkbox-custome {
  --background: #fff;
  --border: #000000;
  --border-hover: #bbc1e1;
  --border-active: #1e2235;
  --tick: #fff;
  position: relative;
  margin: 0;
}

.checkbox-custome label {
  display: flex;
  cursor: pointer;
}

.checkbox-custome input[type=checkbox]:focus,
.checkbox-custome input[type=file]:focus,
.checkbox-custome input[type=radio]:focus {
  outline: none;
}

.checkbox-custome input, .checkbox-custome svg {
  width: 21px;
  height: 21px;
  display: block;
  outline: none;
}

.checkbox-custome input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: var(--background);
  border: none;
  margin: 0 10px 0 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}

.checkbox-custome input:hover {
  --s: 1px;
  --b: var(--border-hover);
}

.checkbox-custome input:checked {
  --b: var(--border-active);
}

.checkbox-custome svg {
  pointer-events: none;
  fill: none;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #efb007;
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  -webkit-transform: scale(var(--scale, 1)) translateZ(0);
  transform: scale(var(--scale, 1)) translateZ(0);
}

.checkbox-custome span {
  display: inline-block;
  vertical-align: middle;
  color: black;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 767px) {
  .checkbox-custome span {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .checkbox-custome span {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.checkbox-custome.path input:checked {
  --s: 2px;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.checkbox-custome.path input:checked + svg {
  --a: 16.1 86.12;
  --o: 102.22;
}

.checkbox-custome.path svg {
  stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  -webkit-transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
  transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}

.social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.social__item {
  display: block;
  position: relative;
}

.social__item + .social__item {
  margin-left: 5px;
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  color: #fff;
  font-size: 16px;
  transition: all 0.3s linear;
}

.social__link:hover {
  text-decoration: none;
  background-color: #efb007;
  border-color: #fff;
  color: #fff;
}

.social__link:active, .social__link.nice-select.open {
  text-decoration: none;
  background-color: #27943F;
  border-color: #fff;
  color: #fff;
}

.s-hero {
  display: block;
  position: relative;
}

.s-hero__inner {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #ffb201;
}

@media only screen and (max-width: 1023px) {
  .s-hero__inner {
    flex-direction: column-reverse;
  }
}

.s-hero__inner > * {
  width: 50%;
}

@media only screen and (max-width: 1023px) {
  .s-hero__inner > * {
    width: 100%;
  }
}

.s-hero__desc {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-image: url(../img/bg_hero.jpg);
  background-size: cover;
  padding: 0 125px;
  padding: 0 6.51042vw;
}

@media only screen and (max-width: 1023px) {
  .s-hero__desc {
    padding: 30px;
  }
}

@media only screen and (max-width: 639px) {
  .s-hero__desc {
    padding: 30px 15px;
  }
}

.s-hero__title {
  color: white;
  font-size: 54px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 25px;
  margin-bottom: 1.30208vw;
}

@media only screen and (max-width: 1679px) {
  .s-hero__title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1439px) {
  .s-hero__title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .s-hero__title {
    margin-bottom: 10px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 639px) {
  .s-hero__title {
    font-size: 20px;
  }
}

.s-hero__subtitle {
  color: white;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 35px;
  margin-bottom: 1.82292vw;
}

@media only screen and (max-width: 1679px) {
  .s-hero__subtitle {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1359px) {
  .s-hero__subtitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1023px) {
  .s-hero__subtitle {
    margin-bottom: 25px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 639px) {
  .s-hero__subtitle {
    font-size: 14px;
  }
}

.s-hero__search {
  width: 100%;
}

.s-hero__slider {
  display: block;
  position: relative;
}

.s-hero__slider .swiper::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 300px;
  background-image: linear-gradient(to top, black 0%, transparent 100%);
  pointer-events: none;
  z-index: 1;
}

.s-hero__slider .swiper-slide img {
  width: 100%;
}

.s-hero__slider .swiper-pagination-fraction {
  text-align: right;
  color: white;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  bottom: 60px;
  bottom: 3.125vw;
  padding-right: 100px;
  padding-right: 5.20833vw;
  z-index: 2;
}

@media (max-width: 767px) {
  .s-hero__slider .swiper-pagination-fraction {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-hero__slider .swiper-pagination-fraction {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}

.s-hero__slider .swiper-pagination-current {
  color: white;
  font-size: 48px;
  font-weight: 700;
  line-height: 40px;
}

@media (max-width: 767px) {
  .s-hero__slider .swiper-pagination-current {
    font-size: calc(36px + 20.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-hero__slider .swiper-pagination-current {
    font-size: calc(36px + 12 * (100vw / 1920));
  }
}

.search {
  display: block;
  position: relative;
  flex-grow: 1;
  max-width: 550px;
}

@media only screen and (max-width: 1359px) {
  .search {
    max-width: 400px;
  }
}

@media only screen and (max-width: 1023px) {
  .search {
    max-width: 100%;
  }
}

.search label {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 639px) {
  .search label {
    font-size: 14px;
  }
}

.search .uk-radio {
  border: 2px solid #fff;
}

.search .uk-radio:checked {
  background-color: #efb007;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.search-box {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  outline: 10px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  padding: 18px;
  flex-grow: 1;
}

@media only screen and (max-width: 1359px) {
  .search-input {
    padding: 5px 15px;
  }
}

.search-input img {
  display: block;
  width: 40px;
  height: 40px;
  flex: 0 0 60px;
  padding-right: 20px;
  border-right: 1px solid #ddd;
}

@media only screen and (max-width: 639px) {
  .search-input img {
    width: 30px;
    height: 30px;
    flex: 0 0 40px;
    padding-right: 10px;
  }
}

.search-input input {
  border: none;
  background: none;
  height: 40px;
  padding: 0 16px;
  flex-grow: 1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 639px) {
  .search-input input {
    padding: 0 0 0 10px;
  }
}

.search-btn {
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111;
  color: #fff;
}

@media only screen and (max-width: 1359px) {
  .search-btn {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
  }
}

.s-about {
  display: block;
  position: relative;
  background-image: url(../img/bg_logo.png);
  background-repeat: no-repeat;
  background-position: left 95% center;
}

.s-about__btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

@media only screen and (max-width: 413px) {
  .s-about__btns {
    justify-content: center;
    margin-top: 25px;
  }
}

.s-about__btns a + a {
  margin-left: 15px;
}

@media only screen and (max-width: 413px) {
  .s-about__btns a + a {
    margin-left: 5px;
  }
}

.s-about__btns .uk-icon-button {
  width: 55px;
  height: 55px;
  background-color: #eee;
  border-radius: 30px;
  font-size: 24px;
  color: #222;
}

.s-about__btns .uk-icon-button:hover, .s-about__btns .uk-icon-button:focus, .s-about__btns .uk-icon-button:active, .s-about__btns .uk-icon-button.nice-select.open {
  color: #efb007;
  background-color: #fff;
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
}

@media only screen and (max-width: 413px) {
  .s-about__btns .uk-button-large {
    padding: 0 20px;
  }
}

.s-categories {
  display: block;
  position: relative;
}

.uk-card .uk-card-body {
  position: relative;
  padding-left: 90px;
  color: #555;
}

@media only screen and (max-width: 1599px) {
  .uk-card .uk-card-body {
    padding-left: 40px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .uk-card .uk-card-body {
    padding: 30px 20px 20px 20px;
  }
}

.uk-card .uk-card-icon {
  position: absolute;
  top: 50%;
  left: -55px;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 1599px) {
  .uk-card .uk-card-icon {
    left: 50%;
    transform: translateX(-50%) translateY(0);
    top: -55px;
  }
}

.uk-card .uk-card-icon span {
  background-color: #fff;
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uk-card .uk-card-icon::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: -1;
}

.uk-card .uk-card-icon:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  width: calc(50% + 15px);
  height: calc(100% + 15px);
  background-color: #fff;
  z-index: -1;
}

@media only screen and (max-width: 1599px) {
  .uk-card .uk-card-icon:after {
    width: calc(100% + 15px);
    height: calc(50% + 15px);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(0);
  }
}

.uk-card .uk-card-title {
  color: #222;
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .uk-card .uk-card-title {
    font-size: calc(20px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .uk-card .uk-card-title {
    font-size: calc(20px + 4 * (100vw / 1920));
  }
}

.cat-item {
  position: relative;
}

.cat-item::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
  opacity: 0.95;
  background-image: linear-gradient(to top, black 0%, transparent 100%);
  z-index: 1;
}

.cat-item img {
  min-height: 250px;
  object-fit: cover;
  object-position: center;
}

.cat-item .uk-overlay {
  z-index: 2;
  color: white;
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .cat-item .uk-overlay {
    font-size: calc(18px + 20.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .cat-item .uk-overlay {
    font-size: calc(18px + 12 * (100vw / 1920));
  }
}

.s-video {
  display: block;
  position: relative;
  background-color: #222;
}

.s-video__inner {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

@media only screen and (max-width: 767px) {
  .s-video__inner {
    flex-direction: column;
  }
}

.s-video__inner > * {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .s-video__inner > * {
    width: 100%;
  }
}

.s-video__desc {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  background-image: url(../img/bg_video.png);
  color: #fff;
  padding: 40px 150px;
  padding: 40px 7.8125vw;
}

@media only screen and (max-width: 959px) {
  .s-video__desc {
    padding: 30px 90px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .s-video__desc {
    padding: 30px 30px 50px 30px;
  }
}

@media only screen and (max-width: 639px) {
  .s-video__desc {
    padding: 30px 15px 30px 15px;
    text-align: center;
  }
}

.s-video__desc .section-title span {
  color: #efb007;
}

.s-video__desc .section-title span::before {
  display: none;
}

.s-video__desc .section-title h3 {
  color: #fff;
}

.s-video__desc .section-content a.more {
  color: #fff;
}

@media only screen and (max-width: 639px) {
  .s-video__desc .section-content a.more {
    display: flex;
    justify-content: center;
  }
}

.s-video__img {
  display: block;
}

@media only screen and (max-width: 767px) {
  .s-video__img {
    position: relative;
  }
}

.s-video__img .uk-cover-container {
  min-height: 100%;
}

@media only screen and (max-width: 767px) {
  .s-video__img .uk-cover-container {
    min-height: 250px;
  }
}

.s-video__link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 110px;
  height: 110px;
  background-color: #efb007;
  border-radius: 50%;
  outline: 10px solid rgba(34, 34, 34, 0.2);
}

@media only screen and (max-width: 767px) {
  .s-video__link {
    width: 80px;
    height: 80px;
  }
}

.s-video__link img {
  margin-right: -5px;
}

.solution-item {
  display: block;
  position: relative;
}

.solution-item__box {
  display: block;
  position: relative;
}

.solution-item__box::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 400px;
  background-image: linear-gradient(to top, black 0%, transparent 100%);
  transition: all 0.3s linear;
}

.solution-item__box:hover::before {
  height: 100%;
}

.solution-item__box:hover .solution-item__intro {
  height: auto;
  overflow: visible;
  visibility: visible;
  pointer-events: all;
  transition: all 0.3s linear;
}

.solution-item__box img {
  width: 100%;
}

.solution-item__box .uk-overlay {
  padding: 60px;
  padding: 3.125vw;
}

@media only screen and (max-width: 767px) {
  .solution-item__box .uk-overlay {
    padding: 30px;
  }
}

.solution-item__title {
  margin-bottom: 20px;
}

.solution-item__title span {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .solution-item__title span {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .solution-item__title span {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.solution-item__title h3 {
  color: white;
  font-size: 36px;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 767px) {
  .solution-item__title h3 {
    font-size: calc(24px + 20.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .solution-item__title h3 {
    font-size: calc(24px + 12 * (100vw / 1920));
  }
}

.solution-item__intro {
  color: white;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s linear;
}

.solution-item__link a.more {
  color: #fff;
}

.s-stats {
  display: block;
  position: relative;
}

.s-stats .uk-container {
  padding-bottom: 70px;
}

.stat-item {
  display: block;
  position: relative;
  background-color: white;
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
  padding: 40px;
  padding: 2.08333vw;
  border-right: 2px solid #efb007;
}

@media only screen and (max-width: 639px) {
  .stat-item {
    padding: 20px;
  }
}

.stat-item__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.stat-item__title {
  color: #222;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 767px) {
  .stat-item__title {
    font-size: calc(16px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .stat-item__title {
    font-size: calc(16px + 6 * (100vw / 1920));
  }
}

.stat-item__value {
  color: #222;
  font-size: 80px;
  line-height: 0.9;
  font-weight: 500;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .stat-item__value {
    font-size: calc(50px + 51 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .stat-item__value {
    font-size: calc(50px + 30 * (100vw / 1920));
  }
}

.stat-item__text {
  color: #888;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  margin: 0;
}

@media (max-width: 767px) {
  .stat-item__text {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .stat-item__text {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}

.s-reasons {
  display: block;
  position: relative;
}

.s-reasons__inner {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

@media only screen and (max-width: 1199px) {
  .s-reasons__inner {
    flex-direction: column;
  }
}

.s-reasons__inner > * {
  width: 50%;
}

@media only screen and (max-width: 1199px) {
  .s-reasons__inner > * {
    width: 100%;
  }
}

.s-reasons__media {
  display: block;
  position: relative;
}

@media only screen and (max-width: 1439px) {
  .s-reasons__media .uk-cover {
    left: calc(50% + 50px);
  }
}

@media only screen and (max-width: 1199px) {
  .s-reasons__media .uk-cover {
    left: 50%;
  }
}

.s-reasons__info {
  padding: 40px 100px;
  padding: 40px 5.20833vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

@media only screen and (max-width: 1599px) {
  .s-reasons__info {
    padding: 40px;
  }
}

@media only screen and (max-width: 959px) {
  .s-reasons__info {
    padding: 30px;
  }
}

@media only screen and (max-width: 639px) {
  .s-reasons__info {
    padding: 30px 15px;
  }
}

.s-reasons__info .section-title {
  margin-bottom: 10px;
}

.s-reasons__info .section-content {
  color: #222;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

@media (max-width: 767px) {
  .s-reasons__info .section-content {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-reasons__info .section-content {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}

.reason-item {
  display: block;
  position: relative;
}

.reason-item__img {
  display: block;
  position: relative;
  width: 100%;
}

.reason-item__body {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;
}

@media only screen and (max-width: 767px) {
  .reason-item__body {
    padding-top: 20px;
  }
}

.reason-item__icon {
  flex: 0 0 50px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

@media only screen and (max-width: 767px) {
  .reason-item__icon {
    padding-bottom: 5px;
  }
}

.reason-item__desc {
  display: block;
  position: relative;
  padding-left: 25px;
}

@media only screen and (max-width: 767px) {
  .reason-item__desc {
    padding-left: 15px;
  }
}

.reason-item__title {
  color: #222;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 5px 0;
}

@media (max-width: 767px) {
  .reason-item__title {
    font-size: calc(16px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .reason-item__title {
    font-size: calc(16px + 6 * (100vw / 1920));
  }
}

.reason-item__text {
  color: #555;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  margin: 0;
}

@media (max-width: 767px) {
  .reason-item__text {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .reason-item__text {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.s-reviews .swiper {
  padding: 20px;
  margin: -20px;
}

.review-item {
  display: block;
  position: relative;
  padding: 0 30px;
}

@media only screen and (max-width: 1199px) {
  .review-item {
    padding: 0 20px;
  }
}

.review-item__box {
  background-color: white;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 60px 50px;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 1199px) {
  .review-item__box {
    padding: 40px 30px;
  }
}

.review-item__box:hover {
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
  border: 1px solid #fff;
}

.review-item__box:hover .review-item__icon {
  border: 1px solid #222;
  background-color: #222;
  color: #fff;
}

.review-item__desc {
  display: block;
  position: relative;
}

.review-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -80px;
  width: 60px;
  height: 60px;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 50%;
  color: #efb007;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 1199px) {
  .review-item__icon {
    width: 40px;
    height: 40px;
    left: -50px;
  }
}

.review-item__icon svg {
  width: 30px;
  height: 30px;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 1199px) {
  .review-item__icon svg {
    width: 20px;
    height: 20px;
  }
}

.review-item__title {
  color: #222;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin: 0 0 10px 0;
}

@media (max-width: 767px) {
  .review-item__title {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .review-item__title {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.review-item__text {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

@media (max-width: 767px) {
  .review-item__text {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .review-item__text {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}

.review-item__user {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #eee;
}

@media only screen and (max-width: 1199px) {
  .review-item__user {
    padding-top: 15px;
    margin-top: 15px;
  }
}

.user {
  display: flex;
  align-items: flex-start;
}

.user__avatar {
  width: 50px;
  height: 50px;
  flex: 0 0 50px;
  margin-right: 15px;
}

.user__info {
  display: block;
  position: relative;
}

.user__name {
  color: #222;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

@media (max-width: 767px) {
  .user__name {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .user__name {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.user__position {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.user__rating {
  margin-top: 10px;
}

.rating-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}

.rating-list li {
  color: #ccc;
}

.rating-list li svg {
  width: 12px;
  height: 12px;
}

.rating-list li.active {
  color: #efb007;
}

.info-card {
  display: block;
  position: relative;
  background-color: #f9f9f9;
  background-image: url(../img/bg_info-card.png);
  background-repeat: no-repeat;
  background-position: right 70px top 70px;
}

.info-card__box {
  padding: 60px 110px;
  padding: 3.125vw 5.72917vw;
}

@media only screen and (max-width: 1199px) {
  .info-card__box {
    padding: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .info-card__box {
    padding: 20px;
  }
}

.info-card__head {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  margin-bottom: 1.5625vw;
}

@media only screen and (max-width: 1199px) {
  .info-card__head {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .info-card__head {
    align-items: center;
  }
}

.info-card__head img {
  margin-right: 30px;
  margin-right: 1.5625vw;
}

@media only screen and (max-width: 1199px) {
  .info-card__head img {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .info-card__head img {
    width: 40px;
    height: 40px;
  }
}

.info-card__head span {
  color: #222;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
}

@media (max-width: 767px) {
  .info-card__head span {
    font-size: calc(14px + 17 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .info-card__head span {
    font-size: calc(14px + 10 * (100vw / 1920));
  }
}

.info-card__title {
  color: #222;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .info-card__title {
    font-size: calc(18px + 30.6 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .info-card__title {
    font-size: calc(18px + 18 * (100vw / 1920));
  }
}

@media only screen and (max-width: 767px) {
  .info-card__title {
    margin-bottom: 15px;
  }
}

.info-card__btn {
  margin-bottom: 50px;
  margin-bottom: 2.60417vw;
}

@media only screen and (max-width: 1199px) {
  .info-card__btn {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .info-card__btn .uk-button {
    width: 100%;
  }
}

.info-card.--accent {
  background-color: #efb007;
}

.info-card.--accent .info-card__head span {
  color: #fff;
}

.info-card.--accent .info-card__title {
  color: #fff;
}

.info-card.--accent .info-card__btn .uk-button-default {
  border-color: #fff;
  color: #fff;
}

.info-card.--accent .info-card__btn .uk-button-default .uk-icon {
  color: #fff;
}

.info-card.--accent .info-card__progress .progress-item h6, .info-card.--accent .info-card__progress .progress-item span {
  color: #fff;
}

.info-card.--accent .info-card__progress .progress-item progress {
  height: 8px;
  background-color: #fff;
  margin: 0 20px;
  border-radius: 0;
}

.info-card.--accent .info-card__progress .progress-item progress::-webkit-progress-bar {
  background-color: #fff;
  border-radius: 0;
}

.info-card.--accent .info-card__progress .progress-item progress::-webkit-progress-value {
  background-color: #555;
  border-radius: 0;
}

.progress-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .progress-item {
    flex-wrap: wrap;
  }
}

.progress-item + .progress-item {
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .progress-item + .progress-item {
    margin-top: 10px;
  }
}

.progress-item h6 {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  margin: 0;
  min-width: 140px;
}

@media only screen and (max-width: 767px) {
  .progress-item h6 {
    width: 100%;
    margin-bottom: 5px;
  }
}

.progress-item progress {
  height: 8px;
  background-color: #555;
  margin: 0 20px;
  border-radius: 0;
}

@media only screen and (max-width: 767px) {
  .progress-item progress {
    width: calc(100% - 50px);
    margin: 0 10px 0 0 !important;
  }
}

.progress-item progress::-webkit-progress-bar {
  background-color: #555;
  border-radius: 0;
}

.progress-item progress::-webkit-progress-value {
  background-color: #efb007;
  border-radius: 0;
}

.progress-item span {
  color: #222;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}

.s-news {
  display: block;
  position: relative;
}

.s-news .swiper {
  padding: 20px;
  margin: -20px;
}

.new-item {
  display: block;
  position: relative;
  max-width: 360px;
  margin: 0 auto;
}

.new-item__box {
  display: block;
  position: relative;
  background-color: #fff;
  transition: all 0.5s linear;
}

.new-item__box:hover {
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
}

.new-item__media {
  display: block;
  position: relative;
}

.new-item__body {
  padding: 50px 25px;
}

@media only screen and (max-width: 767px) {
  .new-item__body {
    padding: 30px 15px;
  }
}

.new-item__title span {
  display: block;
  position: relative;
  color: #888;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}

@media (max-width: 767px) {
  .new-item__title span {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .new-item__title span {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.new-item__title h4 {
  color: #222;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin: 20px 0;
}

@media (max-width: 767px) {
  .new-item__title h4 {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .new-item__title h4 {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}

.new-item__date {
  color: #efb007;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
}

.team-user {
  display: block;
  position: relative;
  max-width: 260px;
  border-radius: 2px;
  overflow: hidden;
  margin: 0 auto;
}

.team-user__photo {
  display: block;
  position: relative;
}

.team-user__photo::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s linear;
}

.team-user__photo img {
  width: 100%;
}

.team-user__body {
  padding: 35px;
  background-color: #fff;
}

.team-user__name {
  color: #222;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 5px;
  transition: all 0.3s linear;
}

@media (max-width: 767px) {
  .team-user__name {
    font-size: calc(20px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .team-user__name {
    font-size: calc(20px + 4 * (100vw / 1920));
  }
}

.team-user__position {
  color: #888;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  transition: all 0.3s linear;
}

@media (max-width: 767px) {
  .team-user__position {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .team-user__position {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}

.team-user__social {
  opacity: 0;
  pointer-events: none;
  margin-top: 20px;
  transition: all 0.3s linear;
}

.team-user:hover .team-user__photo:before, .team-user:focus .team-user__photo:before, .team-user:active .team-user__photo:before, .team-user.nice-select.open .team-user__photo:before {
  opacity: 1;
}

.team-user:hover .team-user__body, .team-user:focus .team-user__body, .team-user:active .team-user__body, .team-user.nice-select.open .team-user__body {
  background-color: #222;
}

.team-user:hover .team-user__name,
.team-user:hover .team-user__position, .team-user:focus .team-user__name,
.team-user:focus .team-user__position, .team-user:active .team-user__name, .team-user.nice-select.open .team-user__name,
.team-user:active .team-user__position,
.team-user.nice-select.open .team-user__position {
  color: #fff;
}

.team-user:hover .team-user__social, .team-user:focus .team-user__social, .team-user:active .team-user__social, .team-user.nice-select.open .team-user__social {
  opacity: 1;
  pointer-events: all;
}

.s-form {
  display: block;
  position: relative;
  background-image: url(../img/bg_logo.png);
  background-repeat: no-repeat;
  background-position: right 95% center;
  border-top: 1px solid #eee;
}

.s-form .section-title {
  margin-bottom: 10px;
}

.s-form .section-title h3 {
  font-size: 36px;
}

@media (max-width: 767px) {
  .s-form .section-title h3 {
    font-size: calc(24px + 20.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-form .section-title h3 {
    font-size: calc(24px + 12 * (100vw / 1920));
  }
}

.s-form .section-content p {
  color: #222;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}

@media (max-width: 767px) {
  .s-form .section-content p {
    font-size: calc(14px + 13.6 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-form .section-content p {
    font-size: calc(14px + 8 * (100vw / 1920));
  }
}

.contacts-box {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.contacts-box__img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .contacts-box__img {
    width: 50px;
    height: 50px;
  }
}

.contacts-box__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contacts-box__desc {
  display: block;
  position: relative;
}

.contacts-box__label {
  color: #222;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .contacts-box__label {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .contacts-box__label {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}

.contacts-box__phone {
  color: #222;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
}

@media (max-width: 767px) {
  .contacts-box__phone {
    font-size: calc(20px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .contacts-box__phone {
    font-size: calc(20px + 4 * (100vw / 1920));
  }
}

ul.time-work {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #222;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

@media (max-width: 767px) {
  ul.time-work {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  ul.time-work {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.s-slider {
  display: block;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .s-slider .swiper-slide .uk-overlay {
    padding: 5px;
    text-align: center;
  }
}

.s-slider .swiper-slide img {
  width: 100%;
  min-height: 450px;
  object-fit: cover;
  object-position: center;
}

.s-slider .swiper-slide h1 {
  color: white;
  font-size: 60px;
  font-weight: 700;
  line-height: 66px;
}

@media (max-width: 767px) {
  .s-slider .swiper-slide h1 {
    font-size: calc(24px + 61.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-slider .swiper-slide h1 {
    font-size: calc(24px + 36 * (100vw / 1920));
  }
}

@media only screen and (max-width: 959px) {
  .s-slider .swiper-slide h1 br {
    display: none;
  }
}

.s-slider .swiper-slide .uk-text-lead {
  color: white;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
}

@media (max-width: 767px) {
  .s-slider .swiper-slide .uk-text-lead {
    font-size: calc(18px + 20.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-slider .swiper-slide .uk-text-lead {
    font-size: calc(18px + 12 * (100vw / 1920));
  }
}

@media only screen and (max-width: 959px) {
  .s-slider .swiper-slide .uk-text-lead br {
    display: none;
  }
}

@media only screen and (max-width: 445px) {
  .s-slider .swiper-slide .uk-button + .uk-button {
    margin-left: 0;
    margin-top: 10px;
  }
}

.s-slider .swiper-pagination-fraction {
  justify-content: flex-end;
  text-align: right !important;
  color: white;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  bottom: 60px;
  bottom: 3.125vw;
  padding-right: 100px;
  padding-right: 5.20833vw;
  z-index: 2;
}

@media (max-width: 767px) {
  .s-slider .swiper-pagination-fraction {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-slider .swiper-pagination-fraction {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}

.s-slider .swiper-pagination-current {
  color: white;
  font-size: 48px;
  font-weight: 700;
  line-height: 40px;
}

@media (max-width: 767px) {
  .s-slider .swiper-pagination-current {
    font-size: calc(36px + 20.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .s-slider .swiper-pagination-current {
    font-size: calc(36px + 12 * (100vw / 1920));
  }
}

.search-form {
  display: block;
  position: relative;
  top: -70px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .search-form {
    top: auto;
    padding: 20px 0;
  }
}

.search-form__box {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 3px 32px 8px rgba(0, 0, 0, 0.12);
  padding: 30px 45px;
}

@media only screen and (max-width: 1199px) {
  .search-form__box {
    padding: 20px;
  }
}

.search-form__box .uk-form-label {
  color: #888;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
}

.search-form__box .js-select {
  border: none;
  padding: 0;
}

.search-form__box .js-select .current {
  color: #222;
  font-size: 18px;
  font-weight: 500;
}

.search-form__box .js-date {
  display: block;
  position: relative;
  width: 100%;
  border: none;
  height: 40px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z'%3E%3C/path%3E%3Crect width='1' height='3' x='6' y='2'%3E%3C/rect%3E%3Crect width='1' height='3' x='13' y='2'%3E%3C/rect%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right center;
}

.search-form__box .js-date::placeholder {
  color: #222;
  font-size: 18px;
  font-weight: 500;
}

.search-form__box .uk-button {
  padding: 0;
  width: 100%;
}

.color-accent {
  color: #efb007;
}

.s-features {
  display: block;
  position: relative;
  overflow: hidden;
}

.feature-items {
  margin-top: 70px;
  display: block;
  position: relative;
}

.feature-items::before {
  content: '';
  display: block;
  position: absolute;
  top: 90px;
  left: -50vw;
  width: 150vw;
  height: calc(100% - 45px);
  background-color: #f9f9f9;
}

.feature-items .uk-slider-container {
  padding: 30px;
  margin: -30px;
}

.feature-item {
  display: block;
  position: relative;
}

.feature-item__box {
  display: block;
  position: relative;
  background-color: white;
  box-shadow: 0 0 36px 9px rgba(0, 0, 0, 0.05);
  border-left: 2px solid #222;
  transition: all 0.3s linear;
}

.feature-item__icon {
  padding: 50px 40px 10px 40px;
}

.feature-item__title {
  display: block;
  position: relative;
  color: #222;
  font-size: 24px;
  font-weight: 700;
  padding: 0 40px 30px 40px;
  border-bottom: 1px solid #eee;
  border: none;
  background: none;
}

@media (max-width: 767px) {
  .feature-item__title {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .feature-item__title {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}

.feature-item__text {
  padding: 30px 40px;
  color: #555;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

@media (max-width: 767px) {
  .feature-item__text {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .feature-item__text {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.feature-item__more {
  padding: 0 40px 50px 40px;
}

.feature-item__more a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 50%;
  color: #222;
  transition: all 0.3s linear;
}

.feature-item:hover .feature-item__box {
  border-color: #efb007;
}

.feature-item:hover .feature-item__more a {
  background-color: #222;
  border: 1px solid #222;
  color: #fff;
}

.feature-item .uk-drop {
  margin-top: -50px;
  width: 100%;
}

.feature-item .uk-card-body {
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .feature-item .uk-card-body {
    padding: 15px;
  }
}

.feature-item .uk-card {
  background: #222;
}

.feature-item .uk-card::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 15px 20px;
  border-color: transparent transparent #222 transparent;
  position: absolute;
  top: -15px;
  left: 40px;
}

.feature-item .uk-card .uk-nav li + li {
  margin-top: 1px;
}

.feature-item .uk-card .uk-nav li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 50px;
  background-color: #fff;
  border-radius: 2px;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s linear;
}

@media (max-width: 767px) {
  .feature-item .uk-card .uk-nav li a {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .feature-item .uk-card .uk-nav li a {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}

.feature-item .uk-card .uk-nav li a:hover {
  background: #efb007;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .feature-item .uk-card .uk-nav li a {
    padding: 0 15px;
    height: 40px;
  }
}

.feature-item .uk-card .uk-nav li a.uk-active {
  background: #efb007;
  color: #fff;
}

.s-brands {
  display: block;
  position: relative;
  background-color: #222;
  background-image: url(../img/bg_brands.jpg);
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .s-brands {
    padding-top: 40px;
  }
}

.s-brands .section-title {
  color: #fff;
}

.s-brands .section-title span {
  color: #fff;
}

.s-brands .section-title h3 {
  color: #fff;
}

.s-brands .section-content {
  background-color: #efb007;
  padding: 50px 100px;
  margin-bottom: -50px;
}

@media only screen and (max-width: 959px) {
  .s-brands .section-content {
    padding: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .s-brands .section-content {
    padding: 20px;
  }
}

@media only screen and (max-width: 959px) {
  .s-brands .section-content .uk-slider {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .s-brands .section-content .uk-slider {
    margin-left: -15px;
  }
}

.s-brands .uk-slidenav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: white;
  box-shadow: 0 0 36px 9px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .s-brands .uk-slidenav {
    width: 40px;
    height: 40px;
  }
}

.s-brands .uk-slidenav svg {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 767px) {
  .s-brands .uk-slidenav svg {
    width: 20px;
    height: 20px;
  }
}

.s-brands .uk-position-center-left-out {
  right: calc(100% + 70px);
}

@media only screen and (max-width: 959px) {
  .s-brands .uk-position-center-left-out {
    right: 100%;
  }
}

.s-brands .uk-position-center-right-out {
  left: calc(100% + 70px);
}

@media only screen and (max-width: 959px) {
  .s-brands .uk-position-center-right-out {
    left: calc(100% + 15px);
  }
}

.s-equipments {
  display: block;
  position: relative;
}

.s-equipments .uk-slider-container {
  padding: 35px;
  margin: -35px;
}

@media only screen and (max-width: 1199px) {
  .s-equipments .uk-slider-container {
    padding: 20px;
    margin: -20px;
  }
}

.equipment-item {
  display: block;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 20px 9px rgba(0, 0, 0, 0.05);
}

.equipment-item__box {
  display: block;
  position: relative;
}

.equipment-item__media {
  display: block;
  position: relative;
}

.equipment-item__media a {
  display: block;
  position: relative;
}

.equipment-item__media a img {
  width: 100%;
}

.equipment-item__info {
  display: block;
  position: relative;
  padding: 30px;
}

@media only screen and (max-width: 1199px) {
  .equipment-item__info {
    padding: 15px;
  }
}

.equipment-item__title {
  display: block;
  position: relative;
  margin-bottom: 10px;
}

.equipment-item__title a {
  color: #222;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  transition: all 0.3s linear;
}

@media (max-width: 767px) {
  .equipment-item__title a {
    font-size: calc(16px + 13.6 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-item__title a {
    font-size: calc(16px + 8 * (100vw / 1920));
  }
}

.equipment-item__title a:hover {
  text-decoration: none;
  color: #efb007;
}

.equipment-item__links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.equipment-item__links a {
  display: inline-block;
  position: relative;
  color: #efb007;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

@media (max-width: 767px) {
  .equipment-item__links a {
    font-size: calc(10px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-item__links a {
    font-size: calc(10px + 4 * (100vw / 1920));
  }
}

.equipment-item__links a + a {
  padding-left: 13px;
  margin-left: 13px;
  border-left: 1px solid #dfdfdf;
}

.equipment-item__list {
  display: block;
  position: relative;
  margin: 30px 0;
}

@media only screen and (max-width: 1199px) {
  .equipment-item__list {
    margin: 15px 0;
  }
}

.equipment-item__list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  column-count: 2;
  column-gap: 5px;
}

.equipment-item__list ul li {
  display: block;
  position: relative;
  color: #222;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
  padding-left: 12px;
}

@media (max-width: 767px) {
  .equipment-item__list ul li {
    font-size: calc(10px + 5.1 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-item__list ul li {
    font-size: calc(10px + 3 * (100vw / 1920));
  }
}

.equipment-item__list ul li + li {
  margin-top: 5px;
}

.equipment-item__list ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #efb007;
  border-radius: 3px;
}

.equipment-item__price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 1199px) {
  .equipment-item__price-box {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.equipment-item__price-title {
  color: #222;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
}

@media (max-width: 767px) {
  .equipment-item__price-title {
    font-size: calc(10px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-item__price-title {
    font-size: calc(10px + 4 * (100vw / 1920));
  }
}

.equipment-item__price-title small {
  display: block;
  font-weight: 400;
}

.equipment-item__price {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.equipment-item__price-current {
  color: #222;
  font-size: 34px;
  font-weight: 700;
  line-height: 1;
}

@media (max-width: 767px) {
  .equipment-item__price-current {
    font-size: calc(20px + 23.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-item__price-current {
    font-size: calc(20px + 14 * (100vw / 1920));
  }
}

.equipment-item__price-old {
  color: #393939;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-decoration: line-through;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .equipment-item__price-old {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-item__price-old {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}

.equipment-item__prices {
  padding-top: 30px;
}

@media only screen and (max-width: 1199px) {
  .equipment-item__prices {
    padding-top: 15px;
  }
}

.equipment-item__prices ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.equipment-item__prices ul li {
  color: #222;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  padding-left: 25px;
  background-image: url(../img/arrow-circle.png);
  background-position: left center;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .equipment-item__prices ul li {
    font-size: calc(12px + 3.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-item__prices ul li {
    font-size: calc(12px + 2 * (100vw / 1920));
  }
}

@media only screen and (max-width: 1359px) {
  .equipment-item__prices ul li {
    padding-left: 0;
    background: none;
  }
}

.s-latest-offers {
  display: block;
  position: relative;
  background-image: url(../img/bg_latest-offers.jpg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  background-color: #f9f9f9;
}

@media only screen and (max-width: 767px) {
  .s-latest-offers {
    background-size: 340%;
    background-position: left bottom;
  }
}

.offer-box {
  display: block;
  position: relative;
  padding: 50px 40px;
  background-color: #fff;
  box-shadow: 0 0 36px 9px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 1199px) {
  .offer-box {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-box {
    padding: 15px;
  }
}

.offer-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 767px) {
  .offer-item {
    flex-wrap: wrap;
  }
}

.offer-item + .offer-item {
  margin-top: 40px;
}

@media only screen and (max-width: 1199px) {
  .offer-item + .offer-item {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-item + .offer-item {
    margin-top: 10px;
  }
}

.offer-media {
  width: 100px;
  flex: 0 0 100px;
}

@media only screen and (max-width: 767px) {
  .offer-media {
    flex: 0 0 60px;
    width: 60px;
  }
}

.offer-media img {
  width: 100%;
}

.offer-info {
  margin: 0 20px;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .offer-info {
    margin: 0 0 0 10px;
    max-width: calc(100% - 70px);
  }
}

.offer-title {
  color: #222;
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .offer-title {
    font-size: calc(14px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .offer-title {
    font-size: calc(14px + 6 * (100vw / 1920));
  }
}

.offer-intro {
  color: #555;
  font-size: 16px;
  font-weight: 300;
}

@media (max-width: 767px) {
  .offer-intro {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .offer-intro {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}

.offer-price {
  white-space: nowrap;
  color: #222;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .offer-price {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .offer-price {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}

.offer-price span {
  color: #efb007;
  font-size: 22px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .offer-price span {
    font-size: calc(16px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .offer-price span {
    font-size: calc(16px + 6 * (100vw / 1920));
  }
}

.s-reasons-choose {
  background-color: #222;
  background-image: url(../img/bg_brands.jpg);
  background-position: center;
  background-size: cover;
}

.s-reasons-choose .section-title span {
  color: #efb007;
}

.s-reasons-choose .section-title h3 {
  color: #fff;
}

.s-reasons-choose .reason-item__title,
.s-reasons-choose .reason-item__text {
  color: #fff;
}

.video-link {
  display: inline-flex;
  align-items: center;
  color: #fff;
  max-width: 500px;
}

.video-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100px;
  height: 100px;
  flex: 0 0 100px;
  background-color: #efb007;
  border-radius: 50%;
  outline: 10px solid rgba(34, 34, 34, 0.2);
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .video-link a {
    width: 80px;
    height: 80px;
    flex: 0 0 80px;
    margin-right: 20px;
  }
}

.video-link a img {
  margin-right: -5px;
}

.article-full,
.article-intro {
  display: block;
  position: relative;
}

.article-full__image,
.article-intro__image {
  display: block;
  position: relative;
}

.article-full__image img,
.article-intro__image img {
  display: block;
  width: 100%;
}

.article-full__image .video,
.article-intro__image .video {
  display: block;
  position: relative;
}

.article-full__image .video > a,
.article-intro__image .video > a {
  display: block;
  position: relative;
}

.article-full__image .video > a:before,
.article-intro__image .video > a:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background-image: url(../img/icons/ico-video.png);
}

.article-full__image .uk-slidenav,
.article-intro__image .uk-slidenav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #222;
}

.article-full__image .uk-slidenav:hover,
.article-intro__image .uk-slidenav:hover {
  color: #efb007;
}

.article-full__image .uk-slidenav-previous,
.article-intro__image .uk-slidenav-previous {
  left: 20px;
}

.article-full__image .uk-slidenav-next,
.article-intro__image .uk-slidenav-next {
  right: 20px;
}

.article-full__category,
.article-intro__category {
  display: inline-block;
  position: absolute;
  left: 50px;
  left: 2.6vw;
  bottom: 50px;
  bottom: 2.6vw;
  background-color: #ffffff;
  color: #222222;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 15px;
}

.article-full__title,
.article-intro__title {
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  margin: 30px 0;
}

@media (max-width: 767px) {
  .article-full__title,
  .article-intro__title {
    font-size: calc(24px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .article-full__title,
  .article-intro__title {
    font-size: calc(24px + 6 * (100vw / 1920));
  }
}

.article-full__info,
.article-intro__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.article-full__info > *,
.article-intro__info > * {
  display: flex;
  align-items: center;
  color: #555;
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  border-right: 1px solid #eeeeee;
}

@media (max-width: 767px) {
  .article-full__info > *,
  .article-intro__info > * {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .article-full__info > *,
  .article-intro__info > * {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}

.article-full__info > *:last-child,
.article-intro__info > *:last-child {
  margin-right: 0;
  padding-right: 0;
  border: 0;
}

.article-full__info > * i, .article-full__info > * svg,
.article-intro__info > * i,
.article-intro__info > * svg {
  margin-right: 10px;
  color: #efb007;
}

.article-full__date,
.article-intro__date {
  font-weight: 500;
}

.article-full__author a,
.article-intro__author a {
  display: inline-block;
  color: #555555;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 5px;
  transition: all 0.5s linear;
}

@media (max-width: 767px) {
  .article-full__author a,
  .article-intro__author a {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .article-full__author a,
  .article-intro__author a {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}

.article-full__author a:hover, .article-full__author a:focus, .article-full__author a:active, .article-full__author a.nice-select.open,
.article-intro__author a:hover,
.article-intro__author a:focus,
.article-intro__author a:active,
.article-intro__author a.nice-select.open {
  color: #efb007;
  text-decoration: none;
}

.article-full__comments a,
.article-intro__comments a {
  color: #555555;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.5s linear;
}

@media (max-width: 767px) {
  .article-full__comments a,
  .article-intro__comments a {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .article-full__comments a,
  .article-intro__comments a {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}

.article-full__comments a:hover, .article-full__comments a:focus, .article-full__comments a:active, .article-full__comments a.nice-select.open,
.article-intro__comments a:hover,
.article-intro__comments a:focus,
.article-intro__comments a:active,
.article-intro__comments a.nice-select.open {
  color: #efb007;
  text-decoration: none;
}

.article-full__content blockquote,
.article-intro__content blockquote {
  display: block;
  position: relative;
  margin-top: 40px;
  padding: 40px 40px 40px 90px;
  border-left: 5px solid #efb007;
  background-color: #f9f9f9;
  color: #222222;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  font-style: normal;
  text-align: left;
}

@media (max-width: 767px) {
  .article-full__content blockquote,
  .article-intro__content blockquote {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .article-full__content blockquote,
  .article-intro__content blockquote {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}

.article-full__content blockquote::before,
.article-intro__content blockquote::before {
  content: '';
  display: block;
  width: 27px;
  height: 19px;
  background-image: url(../img/quote-blockquote.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50px;
  left: 35px;
}

.article-full__content blockquote .user,
.article-intro__content blockquote .user {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.article-full__content blockquote .user strong,
.article-intro__content blockquote .user strong {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .article-full__content blockquote .user strong,
  .article-intro__content blockquote .user strong {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .article-full__content blockquote .user strong,
  .article-intro__content blockquote .user strong {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.article-full__content blockquote .user span,
.article-intro__content blockquote .user span {
  color: #888888;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .article-full__content blockquote .user span,
  .article-intro__content blockquote .user span {
    font-size: calc(12px + 3.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .article-full__content blockquote .user span,
  .article-intro__content blockquote .user span {
    font-size: calc(12px + 2 * (100vw / 1920));
  }
}

.article-full__bottom,
.article-intro__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 55px;
  padding-bottom: 35px;
  position: relative;
}

.article-full__bottom:after,
.article-intro__bottom:after {
  display: block;
  position: absolute;
  bottom: -13px;
  left: 0;
  width: 100%;
  height: 13px;
  background-image: url(../img/decor/decor-lines-grey.png);
  content: '';
}

.article-full__bottom .article-full__tags,
.article-intro__bottom .article-full__tags {
  display: flex;
  align-items: center;
}

.article-full__bottom .article-full__tags h6,
.article-intro__bottom .article-full__tags h6 {
  margin: 0 10px 5px 0;
}

.article-full__bottom .article-full__share,
.article-intro__bottom .article-full__share {
  display: flex;
  align-items: center;
}

.article-full__bottom .article-full__share h6,
.article-intro__bottom .article-full__share h6 {
  margin: 0 10px 5px 0;
}

.article-full__bottom .article-full__share .social__link,
.article-intro__bottom .article-full__share .social__link {
  border: 1px solid #ddd;
  color: #ddd;
}

.article-full__bottom .article-full__share .social__link:hover,
.article-intro__bottom .article-full__share .social__link:hover {
  color: #fff;
  border: 1px solid #efb007;
}

.article-full .block-form,
.article-intro .block-form {
  margin-top: 70px;
}

@media only screen and (max-width: 1200px) {
  .article-full .block-form,
  .article-intro .block-form {
    margin-top: 30px;
  }
}

.article-intro + .article-intro {
  margin-top: 60px;
}

@media only screen and (max-width: 639px) {
  .article-intro + .article-intro {
    margin-top: 30px;
  }
}

.section-article-related {
  margin-top: 20px;
  padding-bottom: 0;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .section-article-related {
    margin-top: 30px;
    padding-bottom: 40px;
  }
}

.section-article-related:after {
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 13px;
  background-image: url(../img/decor/decor-lines-grey.png);
  content: '';
}

.section-article-related .section-title {
  margin-bottom: 35px;
}

.section-article-related .section-title .uk-h2 {
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section-article-related .section-title .uk-h2 {
    font-size: calc(24px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .section-article-related .section-title .uk-h2 {
    font-size: calc(24px + 6 * (100vw / 1920));
  }
}

.section-article-reviews {
  display: block;
  position: relative;
  margin-top: 70px;
}

@media only screen and (max-width: 1200px) {
  .section-article-reviews {
    margin-top: 30px;
  }
}

.section-article-reviews .section-title {
  margin-bottom: 40px;
}

.section-article-reviews .section-title .uk-h2 {
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section-article-reviews .section-title .uk-h2 {
    font-size: calc(24px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .section-article-reviews .section-title .uk-h2 {
    font-size: calc(24px + 6 * (100vw / 1920));
  }
}

.widjet {
  display: block;
  position: relative;
}

.widjet + .widjet {
  margin-top: 60px;
}

.widjet__title {
  display: flex;
  align-items: center;
  position: relative;
  color: #222;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 23px;
}

@media (max-width: 767px) {
  .widjet__title {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .widjet__title {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}

.widjet__title::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 30px;
  background-image: url(../img/svgico/logo-icon.svg);
  margin-right: 10px;
}

.widjet__title small {
  margin: 0 5px;
  font-size: 14px;
  font-weight: 300;
}

.widjet-search .uk-search-default {
  display: block;
  position: relative;
  width: 100%;
}

.widjet-search .uk-search-input {
  display: block;
  width: 100%;
  background-color: #ffffff;
}

.widjet-search .uk-search-input:hover, .widjet-search .uk-search-input:focus, .widjet-search .uk-search-input:active, .widjet-search .uk-search-input.nice-select.open {
  background-color: #ffffff;
}

.widjet-search .uk-search-input::placeholder {
  color: #838383;
  font-size: 16px;
  font-weight: 400;
}

.widjet-search .uk-search-icon {
  width: 62px;
}

.widjet-search .uk-search-icon::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  width: 1px;
  height: 24px;
  background-color: #dddddd;
}

.widjet-category .widjet__title {
  margin-bottom: 17px;
}

.list-category {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-category li a {
  display: flex;
  align-items: center;
  color: #222;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  transition: all 0.5s linear;
}

@media (max-width: 767px) {
  .list-category li a {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .list-category li a {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.list-category li a::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 17px;
  margin-right: 13px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon fill='none' stroke='%23000' stroke-width='1.1' points='8.5 7 13.5 10 8.5 13'%3E%3C/polygon%3E%3Ccircle fill='none' stroke='%23000' stroke-width='1.1' cx='10' cy='10' r='9'%3E%3C/circle%3E%3C/svg%3E");
  transition: all 0.5s linear;
}

.list-category li a:hover, .list-category li a:focus, .list-category li a:active, .list-category li a.nice-select.open {
  text-decoration: none;
  color: #efb007;
  transition: all 0.5s linear;
}

.list-category li a:hover::before, .list-category li a:focus::before, .list-category li a:active::before, .list-category li a.nice-select.open::before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon fill='none' stroke='%23efb007' stroke-width='1.1' points='8.5 7 13.5 10 8.5 13'%3E%3C/polygon%3E%3Ccircle fill='none' stroke='%23efb007' stroke-width='1.1' cx='10' cy='10' r='9'%3E%3C/circle%3E%3C/svg%3E");
  transition: all 0.5s linear;
}

.list-articles {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-articles-item {
  display: block;
  position: relative;
}

.list-articles-item + .list-articles-item {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #dfe1ee;
}

.list-articles-item__link {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.list-articles-item__link:hover, .list-articles-item__link:focus, .list-articles-item__link:active, .list-articles-item__link.nice-select.open {
  text-decoration: none;
}

.list-articles-item__img {
  width: 75px;
  height: 75px;
  flex: 0 0 75px;
  margin-right: 20px;
}

.list-articles-item__info {
  display: block;
}

.list-articles-item__title {
  color: #222;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .list-articles-item__title {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .list-articles-item__title {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.list-articles-item__date {
  display: flex;
  align-items: center;
  color: #222;
  font-size: 14px;
  font-weight: 400;
}

.list-articles-item__date .uk-icon {
  color: #efb007;
  margin-right: 5px;
}

.widjet-gallery {
  max-width: 350px;
}

.widjet-gallery .uk-grid-collapse, .widjet-gallery .uk-grid-column-collapse {
  margin-left: -5px;
}

.widjet-gallery .uk-grid-collapse > *, .widjet-gallery .uk-grid-column-collapse > * {
  padding-left: 5px;
}

.widjet-gallery .uk-grid + .uk-grid-collapse, .widjet-gallery .uk-grid + .uk-grid-row-collapse, .widjet-gallery .uk-grid-collapse > .uk-grid-margin, .widjet-gallery .uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 5px;
}

.widjet-tags-cloud .tags-list li a {
  border-color: #ffffff;
}

@media only screen and (max-width: 959px) {
  .widjet-tags-cloud .tags-list li a {
    border-color: #ddd;
  }
  .widjet-tags-cloud .tags-list li a:hover {
    border-color: #efb007;
  }
}

.article-full__image .video > a:before,
.article-intro__image .video > a:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
  background-image: url(../img/ico-video.png);
  content: '';
}

.tags-list {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tags-list li {
  margin-right: 5px;
  margin-bottom: 5px;
}

.tags-list li a {
  -webkit-box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  padding: 0 20px;
  border: 2px solid #dddddd;
  background-color: #ffffff;
  color: #222222;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.86;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
}

.tags-list li a:hover,
.tags-list li a:focus,
.tags-list li a:active,
.tags-list li a.nice-select.open {
  background-color: #edb509;
  border-color: #edb509;
  color: #ffffff;
  text-decoration: none;
}

.contact-page-box {
  display: block;
  position: relative;
}

.contact-page-box .contact-page-sidebar {
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
  color: #e7e7e7;
}

@media only screen and (max-width: 639px) {
  .contact-page-box .contact-page-sidebar {
    padding: 20px;
  }
}

.contact-page-box .contact-page-sidebar .social {
  margin-left: 30px;
}

.contact-page-box .contact-page-sidebar .contacts-list {
  margin: 30px 0;
}

.contact-page-box .block-form {
  height: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 959px) {
  .contact-page-box .block-form {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 639px) {
  .contact-page-box .block-form {
    margin-top: 15px;
  }
}

.contact-page-sidebar {
  background-color: #222;
  border-radius: 4px;
}

.contacts-map {
  display: block;
  position: relative;
}

.contacts-map iframe {
  width: 100%;
  height: 500px;
}

@media only screen and (max-width: 639px) {
  .contacts-map iframe {
    height: 300px;
  }
}

.equipment-detail {
  display: block;
  position: relative;
}

.equipment-detail .section-title {
  margin-bottom: 35px;
}

.equipment-detail .section-title .uk-h2 {
  margin-bottom: 10px;
  font-size: 30px;
}

@media (max-width: 767px) {
  .equipment-detail .section-title .uk-h2 {
    font-size: calc(20px + 17 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-detail .section-title .uk-h2 {
    font-size: calc(20px + 10 * (100vw / 1920));
  }
}

.equipment-detail__gallery {
  margin-bottom: 40px;
}

@media only screen and (max-width: 639px) {
  .equipment-detail__gallery {
    margin-bottom: 25px;
  }
}

.equipment-detail__title {
  color: #222;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .equipment-detail__title {
    font-size: calc(22px + 23.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-detail__title {
    font-size: calc(22px + 14 * (100vw / 1920));
  }
}

.equipment-detail__location {
  color: #222;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 767px) {
  .equipment-detail__location {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-detail__location {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.equipment-detail__location .uk-icon {
  color: #efb007;
  margin-right: 10px;
}

.equipment-detail__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0 60px;
}

@media only screen and (max-width: 639px) {
  .equipment-detail__btns {
    flex-direction: column;
    margin: 25px 0;
  }
}

.equipment-detail__btns a {
  width: calc(50% - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 67px;
  background-color: #fff9e7;
  color: #222;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s linear;
}

.equipment-detail__btns a:hover, .equipment-detail__btns a:focus, .equipment-detail__btns a:active, .equipment-detail__btns a.nice-select.open {
  text-decoration: none;
  background-color: #efb007;
  color: #ffffff;
}

.equipment-detail__btns a:hover svg, .equipment-detail__btns a:focus svg, .equipment-detail__btns a:active svg, .equipment-detail__btns a.nice-select.open svg {
  color: #ffffff;
}

.equipment-detail__btns a svg {
  color: #efb007;
  margin-right: 10px;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 639px) {
  .equipment-detail__btns a {
    width: 100%;
    margin-bottom: 10px;
  }
}

.equipment-detail__specification {
  margin-top: 60px;
}

@media only screen and (max-width: 639px) {
  .equipment-detail__specification {
    margin-top: 40px;
  }
}

.equipment-detail__specification table tr td:last-child {
  font-weight: 600;
}

.equipment-widjet-title {
  color: #222;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

@media (max-width: 767px) {
  .equipment-widjet-title {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-widjet-title {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.equipment-widjet-title:before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 30px;
  background-image: url(../img/svgico/logo-icon.svg);
  margin-right: 10px;
}

.equipment-order {
  background-color: #222;
  border-left: 5px solid #efb007;
  padding: 50px 20px;
  text-align: center;
}

.equipment-order__title {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.equipment-order__price {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.equipment-order__price .current-price {
  color: #ffffff;
  color: white;
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 767px) {
  .equipment-order__price .current-price {
    font-size: calc(30px + 30.6 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-order__price .current-price {
    font-size: calc(30px + 18 * (100vw / 1920));
  }
}

.equipment-order__price .old-price {
  color: #888;
  font-size: 24px;
  font-weight: 400;
  text-decoration: line-through;
}

.equipment-contacts {
  display: block;
  position: relative;
  background-color: #fff;
  padding: 30px;
  margin: 45px 0;
}

@media only screen and (max-width: 767px) {
  .equipment-contacts {
    padding: 0;
  }
}

.equipment-contacts .contacts-list li + li {
  margin-top: 5px;
}

.equipment-contacts .contacts-list li a {
  color: #222;
}

.equipment-contacts .contacts-list li a .uk-icon {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
}

.equipment-contacts .contacts-list li a .label {
  color: #222;
  font-weight: 700;
}

.equipment-contacts .uk-button {
  padding: 0 10px;
}

.equipment-contacts .uk-button + .uk-button {
  margin: 10px 0 0 0;
}

.equipment-form {
  display: block;
  position: relative;
  background-color: #fff;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .equipment-form {
    padding: 0;
  }
}

.equipment-form .nice-select {
  display: flex;
  align-items: center;
  height: 62px;
  border: none;
  padding-left: 30px;
  border-radius: 2px;
  background-color: #f7f7f7;
}

.equipment-form .nice-select .current {
  color: #888;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .equipment-form .nice-select .current {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-form .nice-select .current {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.equipment-form .nice-select .option {
  padding-left: 30px;
}

.list-checked {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-checked li {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.list-checked li + li {
  margin-top: 5px;
}

.list-checked li::before {
  display: block;
  width: 17px;
  height: 17px;
  margin-right: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23efb007' stroke-width='1.1' points='4,10 8,15 17,4'%3E%3C/polyline%3E%3C/svg%3E");
  content: '';
}

@media only screen and (max-width: 1199px) {
  .js-filter-content.sidebar {
    background: none;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .js-filter-content.sidebar::after {
    display: none;
  }
}

.range-box {
  display: block;
  position: relative;
  text-align: center;
}

.range-box .irs--round {
  height: 20px;
  top: -25px;
}

.range-box .irs--round .irs-line {
  background-color: #ddd;
}

.range-box .irs--round .irs-bar {
  background-color: #575964;
}

.range-box .irs--round .irs-handle {
  top: 15px;
  width: 45px;
  height: 45px;
  border: none;
  background-color: transparent;
  background-image: url(../img/ico-range-slider.svg);
  background-size: cover;
  border-radius: 0;
  box-shadow: none;
}

.range-box__desc {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  margin-top: 20px;
  border-bottom: 1px solid #222;
}

.range-box__desc input {
  display: inline-block;
  background: none;
  outline: none;
  border: none;
  width: 40px;
  min-width: auto;
  text-align: center;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  pointer-events: none;
  margin-top: 2px;
}

.js-filter-content {
  display: block;
}

@media only screen and (max-width: 959px) {
  .js-filter-content {
    display: none;
  }
}

.rental-item {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 959px) {
  .rental-item {
    flex-wrap: wrap;
  }
}

.rental-item + .rental-item {
  margin-top: 45px;
}

@media only screen and (max-width: 639px) {
  .rental-item + .rental-item {
    margin-top: 20px;
  }
}

.rental-item__media {
  display: block;
  position: relative;
}

@media only screen and (max-width: 959px) {
  .rental-item__media {
    width: 48%;
    order: 1;
  }
}

@media only screen and (max-width: 639px) {
  .rental-item__media {
    width: 100%;
    order: 1;
  }
}

.rental-item__media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.rental-item__links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.rental-item__links a {
  color: #efb007;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .rental-item__links a {
    font-size: calc(12px + 3.4 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .rental-item__links a {
    font-size: calc(12px + 2 * (100vw / 1920));
  }
}

.rental-item__links a + a {
  margin-left: 13px;
  padding-left: 13px;
  border-left: 1px solid #dfdfdf;
}

.rental-item__desc {
  margin: 0 30px;
  flex: 1;
  border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 959px) {
  .rental-item__desc {
    width: 100%;
    order: 3;
    margin: 30px 0 0 0;
    border-bottom: none;
  }
}

@media only screen and (max-width: 639px) {
  .rental-item__desc {
    width: 100%;
    order: 2;
    margin: 20px 0;
  }
}

.rental-item__title {
  color: #222222;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.rental-item__specifications ul {
  margin: 0;
  column-gap: 10px;
}

.rental-item__specifications ul li {
  padding-left: 20px;
  color: #222222;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
}

.rental-item__specifications ul li::before {
  width: 5px;
  left: -15px;
  color: #efb007;
}

.rental-item__price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 959px) {
  .rental-item__price {
    width: 48%;
    order: 2;
    text-align: center;
  }
}

@media only screen and (max-width: 639px) {
  .rental-item__price {
    width: 100%;
    order: 3;
  }
}

.rental-item__price-box {
  background-color: #ffffff;
  text-align: center;
  padding: 30px 20px;
  border: 1px solid #eee;
  border-bottom: none;
  flex-grow: 1;
}

.rental-item__price-title {
  color: #222222;
  font-size: 14px;
  margin-bottom: 22px;
}

.rental-item__price-title strong {
  display: block;
  font-weight: 600;
  line-height: 1.1;
}

.rental-item__price-title span {
  display: block;
  line-height: 1.1;
  font-weight: 400;
}

.rental-item__price-current {
  color: #222;
  font-size: 34px;
  font-weight: 700;
  line-height: 1;
}

.rental-item__price-old {
  color: #393939;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: line-through;
}

@media only screen and (max-width: 959px) {
  .rental-item__price-btn {
    width: 100%;
  }
}

@media only screen and (max-width: 959px) {
  .rental-item__price-btn .uk-button {
    width: 100%;
  }
}

.widjet-filter .uk-form-label {
  color: #888;
  font-size: 16px;
  font-weight: 400;
}

.widjet-filter input {
  border: none;
  border-bottom: 1px solid #eee;
  background: none;
  border-radius: 0;
  padding-left: 0;
  color: #222;
  font-size: 18px;
  font-weight: 500;
}

.widjet-filter input::placeholder {
  color: #222;
  font-size: 18px;
  font-weight: 500;
}

.widjet-filter .nice-select {
  border: none;
  border-bottom: 1px solid #eee;
  background: none;
  border-radius: 0;
  padding-left: 0;
}

.widjet-filter .nice-select .current {
  color: #222;
  font-size: 18px;
  font-weight: 500;
}

.equipment-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding-left: 30px;
  margin-bottom: 60px;
  color: #555;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

@media (max-width: 767px) {
  .equipment-head {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .equipment-head {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}

@media only screen and (max-width: 767px) {
  .equipment-head {
    padding-left: 5px;
    margin-bottom: 20px;
  }
}

.equipment-head > * {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .equipment-head > div:first-child {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .equipment-head > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }
}

.sort-box {
  display: flex;
  align-items: center;
}

.sort-box > span {
  color: #555;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

@media (max-width: 767px) {
  .sort-box > span {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .sort-box > span {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}

@media only screen and (max-width: 767px) {
  .sort-box > span {
    display: none;
  }
}

.sort-box .nice-select {
  border: none;
  background: none;
  border-radius: 0;
  padding-left: 15px;
  height: auto;
}

.sort-box .nice-select .current {
  color: #222;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .sort-box .nice-select .current {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}

@media only screen and (max-width: 1920px) and (min-width: 767px) {
  .sort-box .nice-select .current {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}

.view-box {
  display: flex;
  justify-content: flex-end;
  border-left: 1px solid #dfe1ee;
  margin-left: 30px;
}

.view-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  color: #222;
}

@media only screen and (max-width: 767px) {
  .view-item {
    width: 50px;
    height: 50px;
  }
}

.view-item:hover {
  color: #222;
}

.view-item.active {
  background: #222;
  color: #fff;
}

.view-item.active:hover {
  color: #fff;
}
