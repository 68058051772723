.s-hero {
  display: block;
  position: relative;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: #ffb201;
    @include r(1023) {
      flex-direction: column-reverse; }
    & > * {
      width: 50%;
      @include r(1023) {
        width: 100%; } } }
  &__desc {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background-image: url(../img/bg_hero.jpg);
    background-size: cover;
    padding: 0 125px;
    padding: 0 vw(125px);
    @include r(1023) {
      padding: 30px; }
    @include r(639) {
      padding: 30px 15px; } }
  &__title {
    color: white;
    font-size: 54px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 25px;
    margin-bottom: vw(25px);
    @include r(1679) {
      font-size: 40px; }
    @include r(1439) {
      font-size: 30px; }
    @include r(1023) {
      margin-bottom: 10px;
      font-size: 24px; }
    @include r(639) {
      font-size: 20px; } }
  &__subtitle {
    color: white;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 35px;
    margin-bottom: vw(35px);
    @include r(1679) {
      font-size: 24px; }
    @include r(1359) {
      font-size: 18px; }
    @include r(1023) {
      margin-bottom: 25px;
      font-size: 16px; }
    @include r(639) {
      font-size: 14px; } }
  &__search {
    width: 100%; }
  &__slider {
    display: block;
    position: relative;
    .swiper {
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 300px;
        background-image: linear-gradient(to top, black 0%, transparent 100%);
        pointer-events: none;
        z-index: 1; } }
    .swiper-slide {
      img {
        width: 100%; } }
    .swiper-pagination-fraction {
      text-align: right;
      color: white;
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
      bottom: 60px;
      bottom: vw(60px);
      padding-right: 100px;
      padding-right: vw(100px);
      z-index: 2;
      @include fz(24, 18); }
    .swiper-pagination-current {
      color: white;
      font-size: 48px;
      font-weight: 700;
      line-height: 40px;
      @include fz(48, 36); } } }

.search {
  display: block;
  position: relative;
  flex-grow: 1;
  max-width: 550px;
  @include r(1359) {
    max-width: 400px; }
  @include r(1023) {
    max-width: 100%; }
  label {
    color: white;
    font-size: 18px;
    font-weight: 500;
    @include r(639) {
      font-size: 14px; } }

  .uk-radio {
    border: 2px solid #fff;
    &:checked {
      background-color: $accent;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); } } }

.search-box {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  outline: 10px solid rgba(#fff, 0.3);
  margin-bottom: 20px; }

.search-input {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  padding: 18px;
  flex-grow: 1;
  @include r(1359) {
    padding: 5px 15px; }
  img {
    display: block;
    width: 40px;
    height: 40px;
    flex: 0 0 60px;
    padding-right: 20px;
    border-right: 1px solid #ddd;
    @include r(639) {
      width: 30px;
      height: 30px;
      flex: 0 0 40px;
      padding-right: 10px; } }

  input {
    border: none;
    background: none;
    height: 40px;
    padding: 0 16px;
    flex-grow: 1;
    width: 100%;
    @include line(1);
    @include r(639) {
      padding: 0 0 0 10px; } } }

.search-btn {
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111;
  color: #fff;
  @include r(1359) {
    width: 50px;
    height: 50px;
    flex: 0 0 50px; } }
