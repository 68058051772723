.s-reasons-choose {
  background-color: #222;
  background-image: url(../img/bg_brands.jpg);
  background-position: center;
  background-size: cover;

  .section-title {
    span {
      color: $accent; }
    h3 {
      color: #fff; } }

  .reason-item__title,
  .reason-item__text {
    color: #fff; } }


.video-link {
  display: inline-flex;
  align-items: center;
  color: #fff;
  max-width: 500px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 100px;
    height: 100px;
    flex: 0 0 100px;
    background-color: $accent;
    border-radius: 50%;
    outline: 10px solid rgba(#222, 0.2);
    margin-right: 30px;
    @include r(767) {
      width: 80px;
      height: 80px;
      flex: 0 0 80px;
      margin-right: 20px; }
    img {
      margin-right: -5px; } } }
