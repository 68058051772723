

.team-user {
  display: block;
  position: relative;
  max-width: 260px;
  border-radius: 2px;
  overflow: hidden;
  margin: 0 auto;
  &__photo {
    display: block;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.5);
      opacity: 0;
      @include tr(0.3s); }
    img {
      width: 100%; } }
  &__body {
    padding: 35px;
    background-color: #fff; }
  &__name {
    color: #222;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 5px;
    @include tr(0.3s);
    @include fz(24, 20); }
  &__position {
    color: #888;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    @include tr(0.3s);
    @include fz(16, 12); }
  &__social {
    opacity: 0;
    pointer-events: none;
    margin-top: 20px;
    @include tr(0.3s); }

  @include hh {
    .team-user__photo:before {
      opacity: 1; }
    .team-user__body {
      background-color: #222; }
    .team-user__name,
    .team-user__position {
      color: #fff; }
    .team-user__social {
      opacity: 1;
      pointer-events: all; } } }
