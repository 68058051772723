@font-face {
  font-family: 'footable';
  src: url('../fonts/footable.eot');
  src: url('../fonts/footable.eot') format("embedded-opentype"), url('../fonts/footable.woff2') format("woff2"), url('../fonts/footable.woff') format("woff"), url('../fonts/footable.ttf') format("truetype"), url('../fonts/footable.svg') format("svg");
  font-weight: normal;
  font-style: normal; }

.fooicon {
 display: inline-block;
 font-size: inherit;
 font-family: footable!important;
 font-style: normal;
 font-weight: 400;
 line-height: 1;
 text-rendering: auto;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 transform: translate(0); }

.fooicon:after,
.fooicon:before {
 box-sizing:border-box {} }

.fooicon-loader:before {
  content: '\e800'; }
.fooicon-plus:before {
  content: '\e801'; }
.fooicon-minus:before {
  content: '\e802'; }
.fooicon-search:before {
  content: '\e803'; }
.fooicon-remove:before {
  content: '\e804'; }
.fooicon-pencil:before {
  content: '\e805'; }
.fooicon-flash:before {
  content: '\e806'; }
.fooicon-eye-close:before {
  content: '\e807'; }
.fooicon-cog:before {
  content: '\e808'; }
.fooicon-stats:before {
  content: '\e809'; }
.fooicon-sort:before {
  content: '\f0dc'; }
.fooicon-sort-asc:before {
  content: '\f160'; }
.fooicon-sort-desc:before {
  content: '\f161'; }
.fooicon-trash:before {
  content: '\f1f8'; }
