@font-face {
    font-family: "icons";
    src: url('../fonts/icons.eot');
    src: url('../fonts/icons.eot?#iefix') format('eot'), url('../fonts/icons.woff') format('woff'),url('../fonts/icons.woff2') format('woff2'), url('../fonts/icons.ttf') format('truetype'), url('../fonts/icons.svg#icons') format('svg'); }


@mixin icon-styles {
    font-family: "icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    line-height: 1em; }


%icon {
    @include icon-styles; }

@function icon-char($filename) {
    $char: "";

    @if $filename == arrow-down {
        $char: "\E001"; }

    @if $filename == arrow-left {
        $char: "\E002"; }

    @if $filename == arrow-right {
        $char: "\E003"; }

    @if $filename == arrow-up {
        $char: "\E004"; }

    @if $filename == checkmark {
        $char: "\E005"; }

    @if $filename == close {
        $char: "\E006"; }

    @if $filename == contacts {
        $char: "\E007"; }

    @if $filename == copy {
        $char: "\E008"; }

    @if $filename == edit {
        $char: "\E009"; }

    @if $filename == logo-icon {
        $char: "\E00A"; }

    @if $filename == mission {
        $char: "\E00B"; }

    @if $filename == submenu {
        $char: "\E00C"; }

    @if $filename == track {
        $char: "\E00D"; }

    @if $filename == trash {
        $char: "\E00E"; }

    @if $filename == truck-plow {
        $char: "\E00F"; }

    @if $filename == user {
        $char: "\E010"; }

    @return $char; }


@mixin icon($filename, $insert: before, $extend: true) {
    &:#{$insert} {
        @if $extend {
            @extend %icon; }
        @else {
            @include icon-styles; }

        content: icon-char($filename); } }

.ico_arrow-down {
    @include icon(arrow-down); }
.ico_arrow-left {
    @include icon(arrow-left); }
.ico_arrow-right {
    @include icon(arrow-right); }
.ico_arrow-up {
    @include icon(arrow-up); }
.ico_checkmark {
    @include icon(checkmark); }
.ico_close {
    @include icon(close); }
.ico_contacts {
    @include icon(contacts); }
.ico_copy {
    @include icon(copy); }
.ico_edit {
    @include icon(edit); }
.ico_logo-icon {
    @include icon(logo-icon); }
.ico_mission {
    @include icon(mission); }
.ico_submenu {
    @include icon(submenu); }
.ico_track {
    @include icon(track); }
.ico_trash {
    @include icon(trash); }
.ico_truck-plow {
    @include icon(truck-plow); }
.ico_user {
    @include icon(user); }
