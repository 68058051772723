.s-reviews {
  .swiper {
    padding: 20px;
    margin: -20px; } }

.review-item {
  display: block;
  position: relative;
  padding: 0 30px;
  @include r(1199) {
    padding: 0 20px; }
  &__box {
    background-color: white;
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 60px 50px;
    @include r(1199) {
      padding: 40px 30px; }
    @include tr(0.3s);
    @include h {
      box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
      border: 1px solid #fff;
      .review-item__icon {
        border: 1px solid #222;
        background-color: #222;
        color: #fff; } } }
  &__desc {
    display: block;
    position: relative; }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -80px;
    width: 60px;
    height: 60px;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 50%;
    color: $accent;
    @include tr(0.3s);
    @include r(1199) {
      width: 40px;
      height: 40px;
      left: -50px; }
    svg {
      width: 30px;
      height: 30px;
      @include tr(0.3s);
      @include r(1199) {
        width: 20px;
        height: 20px; } } }
  &__title {
    color: #222;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    margin: 0 0 10px 0;
    @include fz(18, 14); }
  &__text {
    color: #555;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    @include fz(16, 12); }
  &__user {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #eee;
    @include r(1199) {
      padding-top: 15px;
      margin-top: 15px; } } }

.user {
  display: flex;
  align-items: flex-start;
  &__avatar {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    margin-right: 15px; }
  &__info {
    display: block;
    position: relative; }
  &__name {
    color: #222;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    @include fz(18, 14); }
  &__position {
    color: #888;
    font-size: 14px;
    font-weight: 400;
    margin: 0; }
  &__rating {
    margin-top: 10px; } }

.rating-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  li {
    color: #ccc;
    svg {
      width: 12px;
      height: 12px; }
    &.active {
      color: $accent; } } }
