.page-wrapper {
  min-width: 320px;
  min-height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  .page-main {
    flex-grow: 1; } }

.page-head {
  display: block;
  position: relative;
  &__bg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 15px;
    height: 300px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    @include r(767) {
      height: 150px; } }
  &__title {
    color: white;
    font-size: 48px;
    font-weight: 700;
    line-height: 66px;
    @include fz(48, 24); }
  &__breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #efb007;
    border-radius: 2px;
    padding: 20px 35px;
    color: #fff;
    z-index: 1;
    @include r(767) {
      padding: 10px 15px;
      width: 90%; }
    .uk-breadcrumb {
      margin: 0;
      & > * {
        & > * {
          color: #fff;
          color: white;
          font-size: 18px;
          font-weight: 500;
          @include fz(18, 12); } }
      & >:last-child {
        > span {
          color: #fff; } }
      &>:nth-child(n+2):not(.uk-first-column):before {
        content: '→';
        color: #fff;
        margin: 0 10px 0 6px; } } } }

.uk-pagination {
  & > * {
    & + * {
      margin-left: 10px; }
    & > * {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 55px;
      height: 55px;
      border: 1px solid #eee;
      border-radius: 2px;
      color: #222;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase; } }
  & > .uk-active {
    & > * {
      background: #222;
      color: #fff; } } }

.sidebar {
  display: block;
  position: relative;
  padding: 0 0 0 40px;
  background-color: #f9f9f9;
  height: 100%;
  @include r(1200) {
    padding: 0 0 0 20px; }
  @include r(959) {
    background: none;
    padding: 0; }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -70px;
    bottom: -70px;
    left: 0;
    width: 50vw;
    height: calc(100% + 140px);
    background-color: #f9f9f9;
    z-index: -1;
    @include r(959) {
      display: none; } }

  &.--left {
    padding: 0 40px 0 0;
    @include r(1199) {
      padding: 0; }
    &:after {
      left: auto;
      right: 0; } } }

.scroll-box {
  scrollbar-color: $accent #E2EBEC;
  scrollbar-width: thin!important;
  -moz-appearance: none !important;

  &::-webkit-scrollbar {
    width: 4px; }

  &::-webkit-scrollbar-track {
    background-color: #E2EBEC; }

  &::-webkit-scrollbar-thumb {
    background-color: $accent; } }

.datepicker--day-name,
.datepicker--cell.-current- {
  color: $accent; }
.datepicker--cell.-selected- {
  background-color: $accent; }


.uk-button {
  border-radius: 2px;
  @include tr(0.3s);
  & > svg {
    margin-left: 15px; }
  .uk-icon {
    color: $accent;
    margin-left: 15px; }

  & + .uk-button {
    margin-left: 10px; } }


.uk-button-default {
  border-color: #222;
  border-radius: 2px;
  color: #222;
  font-weight: 700;
  @include h {
    background-color: $accent;
    border-color: $accent;
    color: #fff;
    .uk-icon {
      color: #fff; } } }

.uk-button-danger {
  background-color: $accent;
  @include hh {
    background-color: darken($accent, 5%); }
  .uk-icon {
    color: #fff; } }

.uk-button-light {
  background-color: #fff;
  color: #222!important;
  font-weight: 700; }

.uk-button-large {
  font-size: 18px;
  text-transform: uppercase;
  @include fz(18, 14);
  @include r(767) {
    height: 45px;
    line-height: 43px;
    padding: 0 20px; } }

.uk-lightbox {
  background-color: rgba(#222, 0.8); }

.slider-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-top: 0;
    &::before {
      color: #ccc; }
    &:after {
      display: none; }
    @include hh {
      color: $accent;
      border-color: $accent;
      &::before {
        color: $accent; } } }

  .swiper-button-prev {
    @include icon(arrow-left); }

  .swiper-button-next {
    @include icon(arrow-right);
    margin-left: 20px; } }

.swiper-pagination-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: #ddd;
    border-radius: 4px;
    opacity: 1; }

  .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background-color: #efb007;
    border-radius: 6px; } }

.uk-input,
.uk-textarea {
  background-color: #f7f7f7;
  border: none;
  border-radius: 2px;
  color: #222;
  font-size: 18px;
  font-weight: 400;
  @include fz(18, 14);
  @include placeholder() {
    color: #888;
    font-size: 18px;
    font-weight: 400;
    @include fz(18, 14); }
  &:focus {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06); } }

.uk-textarea {
  resize: none;
  height: 150px;
  @include r(767) {
    height: 100px; } }

.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 62px;
  padding-left: 30px;
  @include r(767) {
    padding-left: 15px;
    height: 45px; } }

textarea.uk-form-large {
  padding-left: 30px;
  @include r(767) {
    padding-left: 15px; } }

.uk-position-cover {
  background-color: rgba(#000, 0.65); }

.uk-dotnav {
  align-items: center; }

.uk-dotnav>*>* {
  width: 8px;
  height: 8px;
  background-color: #ddd;
  border: none; }

.uk-dotnav>.uk-active>* {
  background-color: $accent;
  width: 10px;
  height: 10px; }

.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
