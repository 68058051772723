

.info-card {
  display: block;
  position: relative;
  background-color: #f9f9f9;
  background-image: url(../img/bg_info-card.png);
  background-repeat: no-repeat;
  background-position: right 70px top 70px;
  &__box {
    padding: 60px 110px;
    padding: vw(60px) vw(110px);
    @include r(1199) {
      padding: 40px; }
    @include r(767) {
      padding: 20px; } }
  &__head {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
    margin-bottom: vw(30px);
    @include r(1199) {
      margin-bottom: 15px; }
    @include r(767) {
      align-items: center; }
    img {
      margin-right: 30px;
      margin-right: vw(30px);
      @include r(1199) {
        margin-right: 15px; }
      @include r(767) {
        width: 40px;
        height: 40px; } }
    span {
      color: #222;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 500;
      @include fz(24, 14); } }
  &__title {
    color: #222;
    font-size: 36px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 35px;
    @include fz(36, 18);
    @include r(767) {
      margin-bottom: 15px; } }
  &__btn {
    margin-bottom: 50px;
    margin-bottom: vw(50px);
    @include r(1199) {
      margin-bottom: 30px; }
    @include r(575) {
      .uk-button {
        width: 100%; } } }
  &__progress {}

  &.--accent {
    background-color: $accent;
    .info-card__head {
      span {
        color: #fff; } }
    .info-card__title {
      color: #fff; }
    .info-card__btn {
      .uk-button-default {
        border-color: #fff;
        color: #fff;
        .uk-icon {
          color: #fff; } } }
    .info-card__progress {
      .progress-item {
        h6, span {
          color: #fff; }
        progress {
          height: 8px;
          background-color: #fff;
          margin: 0 20px;
          border-radius: 0; }
        progress::-webkit-progress-bar {
          background-color: #fff;
          border-radius: 0; }
        progress::-webkit-progress-value {
          background-color: #555;
          border-radius: 0; } } } } }

.progress-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include r(767) {
    flex-wrap: wrap; }
  & + .progress-item {
    margin-top: 15px;
    @include r(767) {
      margin-top: 10px; } }
  h6 {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    margin: 0;
    min-width: 140px;
    @include r(767) {
      width: 100%;
      margin-bottom: 5px; } }
  progress {
    height: 8px;
    background-color: #555;
    margin: 0 20px;
    border-radius: 0;
    @include r(767) {
      width: calc(100% - 50px);
      margin: 0 10px 0 0!important; } }
  progress::-webkit-progress-bar {
    background-color: #555;
    border-radius: 0; }
  progress::-webkit-progress-value {
    background-color: $accent;
    border-radius: 0; }
  span {
    color: #222;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap; } }
