.search-form {
  display: block;
  position: relative;
  top: -70px;
  z-index: 1;
  @include r(767) {
    top: auto;
    padding: 20px 0; }

  &__box {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 3px 32px 8px rgba(0, 0, 0, 0.12);
    padding: 30px 45px;

    @include r(1199) {
      padding: 20px; }

    .uk-form-label {
      color: #888;
      font-size: 16px;
      line-height: 1;
      font-weight: 400; }

    .js-select {
      border: none;
      padding: 0;
      .current {
        color: #222;
        font-size: 18px;
        font-weight: 500; } }

    .js-date {
      display: block;
      position: relative;
      width: 100%;
      border: none;
      height: 40px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z'%3E%3C/path%3E%3Crect width='1' height='3' x='6' y='2'%3E%3C/rect%3E%3Crect width='1' height='3' x='13' y='2'%3E%3C/rect%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
      @include placeholder() {
        color: #222;
        font-size: 18px;
        font-weight: 500; } }

    .uk-button {
      padding: 0;
      width: 100%; } } }
