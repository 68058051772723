.s-form {
  display: block;
  position: relative;
  background-image: url(../img/bg_logo.png);
  background-repeat: no-repeat;
  background-position: right 95% center;
  border-top: 1px solid #eee;

  .section-title {
    margin-bottom: 10px;
    h3 {
      font-size: 36px;
      @include fz(36, 24); } }

  .section-content {
    p {
      color: #222;
      font-size: 22px;
      font-weight: 400;
      line-height: 32px;
      @include fz(22, 14); } } }

.contacts-box {
  margin: 20px 0;
  display: flex;
  align-items: center;
  &__img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    @include r(767) {
      width: 50px;
      height: 50px; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; } }
  &__desc {
    display: block;
    position: relative; }
  &__label {
    color: #222;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 5px;
    @include fz(16, 14); }
  &__phone {
    color: #222;
    font-size: 24px;
    line-height: 1;
    font-weight: 700;
    @include fz(24, 20); } }

ul.time-work {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #222;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  @include fz(18, 14); }
