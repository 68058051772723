// 100 Extra Light or Ultra Light;
// 200 Light or Thin;
// 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black


[class^="ico_"],
[class*=" ico_"] {
  display: inline-flex;
  align-items: center;
  line-height: 1em; }

html,
body {
  font-family: $font-base;
  color: #555;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  @include fz(18, 14); }

h1, h2, h3, h4, h5, h6 {
  font-family: $font-head;
  font-weight: 700; }

.section-title {
  position: relative;
  margin-bottom: 30px;
  span {
    color: #888;
    font-size: 18px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    position: relative;
    @include fz(18, 14);
    &:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 30px;
      background-image: url(../img/svgico/logo-icon.svg);
      margin-right: 10px; } }
  h3 {
    color: #222;
    font-size: 40px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0;
    @include fz(40, 20); }

  &.--center {
    text-align: center;
    span {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      &:before {
        margin-right: 0;
        margin-bottom: 15px; } } } }

a.more {
  display: inline-flex;
  align-items: center;
  color: #222;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
  @include fz(16, 14);
  .uk-icon {
    color: $accent; } }
