.s-categories {
  display: block;
  position: relative; }

.uk-card {
  .uk-card-body {
    position: relative;
    padding-left: 90px;
    color: #555;
    @include r(1599) {
      padding-left: 40px;
      text-align: center; }
    @include r(767) {
      padding: 30px 20px 20px 20px; } }
  .uk-card-icon {
    position: absolute;
    top: 50%;
    left: -55px;
    transform: translateY(-50%);
    background-color: #fff;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    @include r(1599) {
      left: 50%;
      transform: translateX(-50%) translateY(0);
      top: -55px; }
    span {
      background-color: #fff;
      box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
      border-radius: 50%;
      width: 110px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center; }
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 15px);
      height: calc(100% + 15px);
      background-color: rgba(#000, 0.2);
      border-radius: 50%;
      z-index: -1; }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%);
      width: calc(50% + 15px);
      height: calc(100% + 15px);
      background-color: #fff;
      z-index: -1;
      @include r(1599) {
        width: calc(100% + 15px);
        height: calc(50% + 15px);
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(0); } } }
  .uk-card-title {
    color: #222;
    font-size: 24px;
    font-weight: 600;
    @include fz(24, 20); } }


.cat-item {
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    opacity: 0.95;
    background-image: linear-gradient(to top, black 0%, transparent 100%);
    z-index: 1; }
  img {
    min-height: 250px;
    object-fit: cover;
    object-position: center; }
  .uk-overlay {
    z-index: 2;
    color: white;
    font-size: 30px;
    font-weight: 600;
    @include fz(30, 18); } }
