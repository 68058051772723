.s-about {
  display: block;
  position: relative;
  background-image: url(../img/bg_logo.png);
  background-repeat: no-repeat;
  background-position: left 95% center;
  &__btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    @include r(413) {
      justify-content: center;
      margin-top: 25px; }
    a + a {
      margin-left: 15px;
      @include r(413) {
        margin-left: 5px; } }

    .uk-icon-button {
      width: 55px;
      height: 55px;
      background-color: #eee;
      border-radius: 30px;
      font-size: 24px;
      color: #222;
      @include hh {
        color: $accent;
        background-color: #fff;
        box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06); } }

    .uk-button-large {
      @include r(413) {
        padding: 0 20px; } } } }
