.s-news {
  display: block;
  position: relative;
  .swiper {
    padding: 20px;
    margin: -20px; } }

.new-item {
  display: block;
  position: relative;
  max-width: 360px;
  margin: 0 auto;
  &__box {
    display: block;
    position: relative;
    background-color: #fff;
    @include tr(0.5s);
    @include h {
      box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06); } }
  &__media {
    display: block;
    position: relative; }
  &__body {
    padding: 50px 25px;
    @include r(767) {
      padding: 30px 15px; } }
  &__title {
    span {
      display: block;
      position: relative;
      color: #888;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      @include fz(18, 14); }
    h4 {
      color: #222;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
      margin: 20px 0;
      @include fz(24, 18); } }
  &__date {
    color: $accent;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    text-transform: uppercase; } }
