.uk-offcanvas-bar {
  background-color: #111;

  .uk-offcanvas-close {
    right: 20px;
    color: $accent;
    opacity: 1; }

  .contacts-block {
    padding-left: 50px;
    &::before {
      font-size: 40px;
      margin-top: -20px; }
    span, strong {
      display: block!important;
      color: #fff; } } }

.uk-offcanvas-bar {
  .uk-nav-default>li>a {
    color: #fff;
    font-size: 18px; }
  .uk-nav-default .uk-nav-sub a {
    color: #fff;
    font-size: 16px; } }

.uk-offcanvas-bar {
  .uk-nav-parent-icon>.uk-parent>a:after {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Crect fill='%23fff' x='9' y='1' width='1' height='17'%3E%3C/rect%3E%3Crect fill='%23fff' x='1' y='9' width='17' height='1'%3E%3C/rect%3E%3C/svg%3E"); }

  .uk-nav-parent-icon>.uk-parent.uk-open>a:after {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Crect fill='%23fff' height='1' width='18' y='9' x='1'%3E%3C/rect%3E%3C/svg%3E"); } }
