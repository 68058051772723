.newsletter {
  display: block;
  position: relative;
  background-color: $accent;
  background-image: url(../img/bg_newsletter.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include r(1359) {
      flex-direction: column;
      align-items: stretch; } } }

.newsletter-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding-right: 70px;
  padding-right: vw(70px);
  margin-right: 70px;
  margin-right: vw(70px);
  border-right: 1px solid #fff;
  @include r(1439) {
    padding-right: 20px;
    margin-right: 20px; }
  @include r(1359) {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff; }
  @include r(639) {
    flex-direction: column;
    align-items: flex-start; }
  &__title {
    @include r(639) {
      margin-bottom: 10px; }
    h4 {
      color: white;
      font-size: 36px;
      font-weight: 700;
      line-height: 40px;
      margin: 0 15px 0 0;
      @include fz(36, 18);
      br {
        @include r(639) {
          display: none; } } } }
  &__form {
    width: 100%;
    border-radius: 2px;
    border: 10px solid rgba(white, 0.2);
    border: vw(10px) solid rgba(white, 0.2);
    @include r(1199) {
      border: 5px solid rgba(white, 0.2); }
    form {
      background-color: #fff;
      padding: 15px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      @include r(1199) {
        padding: 5px 15px; }
      @include r(639) {
        padding: 5px 5px 5px 10px; }
      input {
        flex-grow: 1;
        border: none;
        width: 100%;
        height: 40px;
        padding-left: 35px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23000' points='1.4,6.5 10,11 18.6,6.5'%3E%3C/polyline%3E%3Cpath d='M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center; }
      button {
        border: none;
        background: none;
        color: #222;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
        border-left: 1px solid #ddd;
        @include fz(20, 12);
        @include r(639) {
          padding: 0 10px; } } } } }

.download-app {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__title {
    margin-right: 30px;
    @include r(639) {
      margin-right: 10px; }
    h4 {
      color: white;
      font-size: 36px;
      line-height: 1;
      font-weight: 700;
      margin: 0 0 10px 0;
      @include fz(36, 16);
      white-space: nowrap;
      @include r(639) {
        margin-bottom: 5px; } }
    p {
      color: white;
      font-size: 20px;
      font-weight: 500;
      padding: 0;
      margin: 0;
      @include fz(20, 12); } }
  &__links {
    display: flex;
    align-items: center; } }

.download-link {
  border-radius: 1px;
  outline: 10px solid rgba(white, 0.2);
  outline: vw(10px) solid rgba(white, 0.2);
  background-color: #fff;
  padding: 15px 35px;
  padding: 15px vw(35px);
  @include r(1199) {
    outline: 5px solid rgba(white, 0.2);
    padding: 10px; }
  & + .download-link {
    margin-left: 30px;
    @include r(639) {
      margin-left: 10px; } }
  img {
    min-width: 40px;
    @include r(1199) {
      min-width: 30px;
      width: 30px;
      height: 30px; } } }


.page-footer {
  display: block;
  position: relative;
  background-color: #111;
  z-index: 1;
  &__top {
    padding-top: 100px;
    padding-bottom: 100px;
    @include r(1199) {
      padding-top: 50px;
      padding-bottom: 50px; } }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include r(1199) {
      flex-wrap: wrap; } }
  &__contacts {
    padding: 50px;
    background-color: #222;
    border-radius: 4px;
    max-width: 390px;
    margin-top: -115px;
    @include r(1359) {
      padding: 50px 30px;
      max-width: 350px; }
    @include r(1199) {
      order: 1;
      width: 50%;
      max-width: calc(50% - 20px);
      margin-bottom: 40px;
      padding: 30px;
      margin-top: -65px; }
    @include r(767) {
      order: 1;
      width: 100%;
      max-width: 100%; }
    .footer-logo {
      margin-bottom: 35px; }
    p {
      color: rgba(#e7e7e7, 0.7);
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
      @include r(1199) {
        font-size: 14px; } } }

  &__menu {
    @include r(1199) {
      order: 3;
      width: 50%; }
    @include r(767) {
      order: 2;
      width: 100%;
      margin-bottom: 30px; }
    h4 {
      color: white;
      font-size: 22px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 40px;
      @include fz(22, 18);
      &:after {
        content: '';
        display: block;
        width: 44px;
        height: 2px;
        background-color: $accent;
        margin-top: 10px; } }
    ul {
      li {
        & + li {
          display: block;
          position: relative;
          margin-top: 25px; }
        a {
          display: flex;
          align-items: center;
          color: rgba(white, 0.7);
          font-size: 18px;
          font-weight: 300;
          line-height: 1;
          padding: 0;
          @include fz(18, 14);
          &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            flex: 0 0 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23fff' points='10 5 15 9.5 10 14'%3E%3C/polyline%3E%3Cline fill='none' stroke='%23fff' x1='4' y1='9.5' x2='15' y2='9.5'%3E%3C/line%3E%3C/svg%3E");
            margin-right: 5px; }
          @include h {
            &::before {
              background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23efb007' points='10 5 15 9.5 10 14'%3E%3C/polyline%3E%3Cline fill='none' stroke='%23efb007' x1='4' y1='9.5' x2='15' y2='9.5'%3E%3C/line%3E%3C/svg%3E"); } } } } } }

  &__news {
    @include r(1199) {
      order: 2;
      width: 50%; }
    @include r(767) {
      order: 3;
      width: 100%; }
    h4 {
      color: white;
      font-size: 22px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 40px;
      @include fz(22, 18);
      &:after {
        content: '';
        display: block;
        width: 44px;
        height: 2px;
        background-color: $accent;
        margin-top: 10px; } } }

  &__bottom {
    padding-top: 45px;
    padding-bottom: 45px;
    border-top: 1px solid rgba(white, 0.3);
    @include r(1199) {
      padding-top: 25px;
      padding-bottom: 25px; } }

  &__copy {
    text-align: center;
    color: white;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    @include r(1199) {
      font-size: 14px; }
    @include r(639) {
      font-size: 12px; }
    span {
      display: inline-block;
      margin-right: 20px;
      @include r(639) {
        margin-right: 0;
        display: block;
        margin-bottom: 10px; } }
    a {
      color: rgba(white, 0.3);
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
      text-decoration: underline;
      @include tr(0.3s);
      @include h {
        color: $accent; }
      @include r(1199) {
        font-size: 14px; }
      @include r(639) {
        font-size: 10px; }
      & + a {
        padding-left: 10px;
        margin-left: 10px;
        border-left: 1px solid rgba(white, 0.3); } } } }

.contacts-list {
  list-style-type: none;
  padding: 0;
  margin: 30px 0;
  li {
    display: block;
    position: relative;
    a {
      display: flex;
      align-items: baseline;
      color: white;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      @include r(1199) {
        font-size: 16px; } }
    & + li {
      margin-top: 20px;
      @include r(1199) {
        margin-top: 10px; } }
    span.label {
      display: block;
      color: $accent; }
    span.uk-icon {
      margin-right: 15px;
      @include r(1199) {
        margin-right: 10px; } } } }

.social {
  li {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: rgba(#fff, 0.1);
      color: #fff;
      @include tr(0.3s);
      @include h {
        background-color: $accent; } }
    & + li {
      margin-left: 5px; } } }

.news-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    & + li {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgba(#fff, 0.1); } } }

.news-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 300px;
  &__img {
    width: 95px;
    height: 88px;
    margin-right: 20px; }
  &__info {
    display: flex;
    flex-direction: column; }
  &__date {
    color: #efb007;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 15px; }
  &__title {
    color: rgba(white, 0.7);
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2;
    @include tr(0.3);
    @include h {
      color: $accent; }
    @include fz(18, 16); } }

