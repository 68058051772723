.page-header {
  display: block;
  position: relative;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 100px;
  @include r(1799) {
    padding: 0 40px; }
  @include r(959) {
    padding: 0 0 0 30px; }
  @include r(639) {
    padding: 0 0 0 15px; }
  &.uk-active {
    box-shadow: 2px 2px 10px 0 rgba(#000, 0.15); }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &__contacts-list {
    margin-right: auto;
    @include r(1023) {
      display: none; }
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: block;
        position: relative;
        & + li {
          margin-left: 20px; }
        a {
          display: flex;
          align-items: center;
          color: white;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          @include fz(18, 14);
          .uk-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            width: 36px;
            height: 36px;
            background-color: rgba(#fff, 0.2);
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            @include r(1365) {
              width: 30px;
              height: 30px; } } } } } }

  &__logo {
    margin-right: auto;
    padding-top: 22px;
    padding-bottom: 22px;
    @include r(1365) {
      padding-top: 12px;
      padding-bottom: 12px; } }
  &__mainmenu {
    margin-left: 20px;
    @include r(959) {
      display: none; }
    .uk-navbar-nav {
      & > li {
        & > a {
          color: #222;
          font-family: Barlow;
          font-size: 20px;
          font-weight: 600;
          text-transform: none;
          min-height: 50px;
          @include fz(20, 12px);
          @include r(1365) {
            padding: 0 10px;
            font-size: 16px; }
          @include tr(0.3);
          @include h {
            color: $accent; }
          &::before {
            content: '[';
            display: none;
            position: relative; }
          &::after {
            content: ']';
            display: none;
            position: relative; }
          span {
            display: block;
            position: relative;
            &::before {
              content: '';
              display: block;
              position: absolute;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);
              width: 20px;
              height: 20px;
              background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='10.5,16.08 5.63,10.66 6.37,10 10.5,14.58 14.63,10 15.37,10.66'%3E%3C/polygon%3E%3Cline fill='none' stroke='%23000' x1='10.5' y1='4' x2='10.5' y2='15'%3E%3C/line%3E%3C/svg%3E");
              opacity: 0; } } }

        &.uk-active {
          & > a {
            color: $accent;
            &:before,
            &:after {
              display: inline-block; }
            span {
              &::before {
                opacity: 1; } } } } }


      .uk-navbar-dropdown {
        width: 250px; } } }

  &__search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    @include r(1365) {
      width: 60px;
      height: 60px; }
    @include r(959) {
      border-left: 1px solid #eee; }
    .uk-search-icon {
      svg {
        width: 24px;
        height: 24px; } } }
  &__user {
    .uk-icon-button {
      @include r(959) {
        border-left: 1px solid #eee; } } }

  &__contacts {
    padding-left: 50px;
    border-left: 1px solid #eee;
    padding-top: 25px;
    padding-bottom: 25px;
    @include r(1365) {
      padding-left: 25px;
      padding-top: 12px;
      padding-bottom: 12px; }
    @include r(959) {
      padding-right: 25px; }
    @include r(639) {
      padding: 0;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center; } }
  &__mobile-menu-btn {
    display: none;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    @include r(959) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px; }
    button {
      background: none;
      border: none;
      svg {
        width: 30px;
        height: 30px; } } } }

.logo {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  img {
    display: block;
    position: relative;
    @include r(1365) {
      max-height: 30px; } }
  span {
    display: inline-block;
    position: relative;
    color: #555;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    margin-left: 15px;
    transform: translateY(2px);
    @include r(1679) {
      display: none; } } }

.contacts-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding-left: 70px;
  @include r(1365) {
    padding-left: 40px; }
  @include r(639) {
    padding-left: 30px; }
  @include icon(contacts);
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -25px;
    font-size: 50px;
    color: $accent;
    @include r(1365) {
      font-size: 30px;
      margin-top: -15px; } }
  span {
    color: #222;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 5px;
    @include r(1365) {
      font-size: 12px; }
    @include r(639) {
      display: none; } }
  strong {
    color: #222;
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    @include r(1365) {
      font-size: 16px; }
    @include r(639) {
      display: none; } } }

.page-header.--two-line {
  padding: 0;
  .page-header__top-line {
    background-color: #222;
    padding: 12px 0;
    @include r(639) {
      display: none; } }
  .page-header__bottom-line {
    padding-top: 12px;
    padding-bottom: 12px;
    background: #fff;
    @include r(959) {
      padding-top: 8px;
      padding-bottom: 0; }
    @include r(639) {
      padding-top: 0; }
    &.uk-active {
      box-shadow: 2px 2px 10px 0 rgba(#000, 0.15);
      .page-header__logo {
        padding-top: 0;
        padding-bottom: 0; } } }
  .page-header__social {
    margin-right: 30px;
    .social__link {
      background-color: transparent;
      @include h {
        background-color: $accent; } } }
  .page-header__contacts {
    display: block;
    position: relative;
    border: none;
    background-color: $accent;
    margin-top: -12px;
    margin-bottom: -20px;
    padding: 12px 30px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0; }

    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 0;
      border-color: transparent #966f0c transparent transparent;
      left: -8px; }
    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 0;
      border-color: #966f0c transparent transparent transparent;
      right: -8px; } }

  .contacts-block {
    padding-left: 50px;
    background-image: url(../img/ico-info-1.png);
    background-size: 40px;
    background-repeat: no-repeat;
    &::before {
      display: none; }
    span {
      color: #fff;
      font-size: 14px; }
    strong {
      color: #fff;
      font-size: 22px; } }
  .uk-icon-button {
    width: 55px;
    height: 55px;
    @include r(959) {
      background: none;
      width: 60px;
      height: 60px;
      border-radius: 0; } }
  .page-header__mainmenu {
    margin: 0 auto; } }
