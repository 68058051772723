@mixin r($width) {
  @media only screen and (max-width: $width+ "px") {
    @content; } }

@mixin rmin($width) {
  @media only screen and (min-width: $width+ "px") {
    @content; } }

@mixin rh($height) {
  @media only screen and (max-height: $height + "px") {
    @content; } }

@mixin rhmin($height) {
  @media only screen and (min-height: $height + "px") {
    @content; } }

@mixin xxl() {
  @media only screen and (min-width: 1399px) {
    @content; } }

@mixin xl() {
  @media only screen and (min-width: 1199px) {
    @content; } }

@mixin lg() {
  @media only screen and (min-width: 991px) {
    @content; } }

@mixin md() {
  @media only screen and (min-width: 767px) {
    @content; } }

@mixin sm() {
  @media only screen and (min-width: 575px) {
    @content; } }


@mixin font($font_name, $file_name, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font_name;
    font-display: swap;
    src: url('../fonts/' + $file_name + '.woff') format('woff'), url('../fonts/' + $file_name + '.woff2') format('woff2');
    font-weight: $weight;
    font-style: $style; } }

@mixin fs($sizeValue: 16) {
  font-size: $sizeValue + px;
  font-size: $sizeValue / 10 + rem; }

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@mixin line($val) {
  @if $val == 1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  @else {
    display: -webkit-box;
    -webkit-line-clamp: $val;
    -webkit-box-orient: vertical;
    overflow: hidden; } }

@mixin h {
  &:hover {
    @content; } }

@mixin f {
  &:focus {
    @content; } }

@mixin a {
  &:active {
    @content; } }

@mixin hh {
  &:hover,
  &:focus,
  &:active {
    @content; } }

@mixin shine {
  .shine:after {
    left: -50%;
    opacity: 1;
    top: -50%;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-property: left, top, opacity;
    transition-timing-function: ease; } }

@mixin ico() {
  [class*='ico_'] {
    @content; } }

$vw-viewport: 1920;
$vh-viewport: 1080;

@function vw($px) {
  $vw-context: $vw-viewport * 0.01 * 1px;
  @return $px / $vw-context * 1vw;
  @return $px; }

@function vh($px) {
  $vw-context: $vh-viewport * 0.01 * 1px;
  @return $px / $vh-context * 1vh;
  @return $px; }

@mixin coverdiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@mixin cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%; }

@mixin box {
  @include box-sizing(border-box); }

@mixin vcenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); }

@mixin g($color1, $color2) {
  background-image: linear-gradient($color1, $color2); }

@mixin gh($color1, $color2) {
  background-image: linear-gradient(left, $color1, $color2); }

@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
  transform: rotate($deg + deg);
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=$m11, M12=$m12,M21=$m21, M22=$m22, sizingMethod='auto expand');
  zoom: 1; }

@mixin tr($what) {
  transition: all $what linear; }

@mixin hide-text {
  font: 0/0 a;
  text-shadow: none;
  color: transparent; }

@mixin hidetext {
  @include hide-text; }

@mixin clr {
  &:after {
    content: " ";
    display: table;
    clear: both; }
  *zoom: 1; }

@mixin placeholder-color($color) {
  &::placeholder {
    color: $color; } }

@mixin placeholder {
  &::placeholder {
    @content; } }

@mixin gray {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(grayScale=1)"; }

@mixin gray-half {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0.5'/></filter></svg>#grayscale");
  filter: gray alpha(opacity = 50);
  -webkit-filter: grayscale(50%); }

@mixin gray-zero {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%); }

@mixin unselectable {
  user-select: none; }

@mixin nosel {
  @include unselectable; }

@mixin prevent-text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto; }

@mixin font-smoothing($val: antialiased) {
  font-smoothing: $val; }

@mixin selection {
  & ::selection {
    @content; } }

@mixin sel {
  @include selection; }

@mixin scrollbar {
  &::-webkit-scrollbar {
    @content; } }

@mixin scrollbar-track {
  &::-webkit-scrollbar-track {
    @content; } }

@mixin scrollbar-thumb {
  &::-webkit-scrollbar-thumb {
    @content; } }

@mixin scrollbar-track-piece {
  &::-webkit-scrollbar-track-piece {
    @content; } }

@mixin arr($width, $height, $bg, $direction) {
  width: 0px;
  height: 0px;
  border-style: solid;
  @if $direction == t {
    border-width: 0 $width / 2 + px $height + px $width / 2 + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == r {
    border-width: $height / 2 + px 0 $height / 2 + px $width + px;
    border-color: transparent transparent transparent $bg; }
  @if $direction == b {
    border-width: $height + px $width / 2 + px 0 $width / 2 + px;
    border-color: $bg transparent transparent transparent; }
  @if $direction == l {
    border-width: $height / 2 + px $width + px $height / 2 + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == tl {
    border-width: $height + px $width + px 0 0;
    border-color: $bg transparent transparent transparent; }
  @if $direction == tr {
    border-width: 0 $width + px $height + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == br {
    border-width: 0 0 $height + px $width + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == bl {
    border-width: $width + px 0 0 $height + px;
    border-color: transparent transparent transparent $bg; } }
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

@mixin rotate($var) {
  transform: rotate($var+deg); }

@mixin blur($var) {
  filter: blur($var); }

@mixin scale($var) {
  transform: scale($var); }

@mixin counter($var, $sep) {
  counter-reset: list + $var;
  > li {
    &:before {
      content: counter(list + $var) $sep;
      counter-increment: list + $var; } } }

@mixin fz($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $addMobSize: $addSize + $addSize * 0.7;
  @media (max-width: 767px) {
    font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth})); }
  @media only screen and (max-width: 1920px) and (min-width: 767px) {
    font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth})); } }
