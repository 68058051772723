.contact-page-box {
  display: block;
  position: relative;

  .contact-page-sidebar {
    height: 100%;
    box-sizing: border-box;
    padding: 40px;
    @include r(639) {
      padding: 20px; }

    color: #e7e7e7;

    .social {
      margin-left: 30px; }

    .contacts-list {
      margin: 30px 0; } }

  .block-form {
    height: 100%;
    box-sizing: border-box;
    @include r(959) {
      margin-top: 30px; }
    @include r(639) {
      margin-top: 15px; } } }

.contact-page-sidebar {
  background-color: #222;
  border-radius: 4px; }

.contacts-map {
  display: block;
  position: relative;
  iframe {
    width: 100%;
    height: 500px;
    @include r(639) {
      height: 300px; } } }
