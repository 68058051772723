.article-full,
.article-intro {
  display: block;
  position: relative;
  &__image {
    display: block;
    position: relative;
    img {
      display: block;
      width: 100%; }

    .video {
      display: block;
      position: relative;
      & > a {
        display: block;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 150px;
          height: 150px;
          background-image: url(../img/icons/ico-video.png); } } }

    .uk-slidenav {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: rgba(#fff, 0.7);
      color: #222;
      @include h {
        color: $accent; } }

    .uk-slidenav-previous {
      left: 20px; }

    .uk-slidenav-next {
      right: 20px; } }

  &__category {
    display: inline-block;
    position: absolute;
    left: 50px;
    left: 2.6vw;
    bottom: 50px;
    bottom: 2.6vw;
    background-color: #ffffff;
    color: #222222;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 15px; }
  &__title {
    color: #222222;
    font-size: 30px;
    @include fz(30, 24);
    font-weight: 700;
    line-height: 1.2;
    text-align: left;
    margin: 30px 0; }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    > * {
      display: flex;
      align-items: center;
      color: #555;
      font-size: 16px;
      @include fz(16, 12);
      font-weight: 400;
      margin-right: 20px;
      padding-right: 20px;
      margin-bottom: 10px;
      border-right: 1px solid #eeeeee;
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border: 0; }
      i, svg {
        margin-right: 10px;
        color: $accent; } } }

  &__date {
    font-weight: 500; }
  &__author {
    a {
      display: inline-block;
      color: #555555;
      font-size: 16px;
      @include fz(16, 12);
      font-weight: 600;
      text-decoration: underline;
      margin-left: 5px;
      @include tr(0.5s);
      @include hh {
        color: $accent;
        text-decoration: none; } } }
  &__comments {
    a {
      color: #555555;
      font-size: 16px;
      @include fz(16, 12);
      font-weight: 400;
      text-decoration: none;
      @include tr(0.5s);
      @include hh {
        color: $accent;
        text-decoration: none; } } }

  &__content {
    blockquote {
      display: block;
      position: relative;
      margin-top: 40px;
      padding: 40px 40px 40px 90px;
      border-left: 5px solid $accent;
      background-color: #f9f9f9;
      color: #222222;
      font-size: 24px;
      @include fz(24, 18);
      font-weight: 500;
      line-height: 1.5;
      font-style: normal;
      text-align: left;
      &::before {
        content: '';
        display: block;
        width: 27px;
        height: 19px;
        background-image: url(../img/quote-blockquote.png);
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 50px;
        left: 35px; }
      .user {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        strong {
          color: #222222;
          font-size: 18px;
          @include fz(18, 14);
          font-weight: 500; }
        span {
          color: #888888;
          font-size: 14px;
          @include fz(14, 12);
          font-weight: 400; } } } }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 55px;
    padding-bottom: 35px;
    position: relative;
    &:after {
      display: block;
      position: absolute;
      bottom: -13px;
      left: 0;
      width: 100%;
      height: 13px;
      background-image: url(../img/decor/decor-lines-grey.png);
      content: ''; }


    .article-full__tags {
      display: flex;
      align-items: center;
      h6 {
        margin: 0 10px 5px 0; } }

    .article-full__share {
      display: flex;
      align-items: center;
      h6 {
        margin: 0 10px 5px 0; }
      .social__link {
        border: 1px solid #ddd;
        color: #ddd;
        @include h {
          color: #fff;
          border: 1px solid $accent; } } } }

  .block-form {
    margin-top: 70px;
    @include r(1200) {
      margin-top: 30px; } } }

.article-intro {
  & + .article-intro {
    margin-top: 60px;
    @include r(639) {
      margin-top: 30px; } } }

.section-article-related {
  margin-top: 20px;
  padding-bottom: 0;
  position: relative;
  @include r(1200) {
    margin-top: 30px;
    padding-bottom: 40px; }
  &:after {
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 13px;
    background-image: url(../img/decor/decor-lines-grey.png);
    content: ''; }
  .section-title {
    margin-bottom: 35px;
    .uk-h2 {
      color: #222222;
      font-size: 30px;
      @include fz(30, 24);
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 0; } } }

.section-article-reviews {
  display: block;
  position: relative;
  margin-top: 70px;
  @include r(1200) {
    margin-top: 30px; }

  .section-title {
    margin-bottom: 40px;
    .uk-h2 {
      color: #222222;
      font-size: 30px;
      @include fz(30, 24);
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 0; } } }

.widjet {
  display: block;
  position: relative;
  & + .widjet {
    margin-top: 60px; }
  &__title {
    display: flex;
    align-items: center;
    position: relative;
    color: #222;
    font-size: 24px;
    @include fz(24, 18);
    font-weight: 700;
    margin-bottom: 23px;
    &::before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 30px;
      background-image: url(../img/svgico/logo-icon.svg);
      margin-right: 10px; }

    small {
      margin: 0 5px;
      font-size: 14px;
      font-weight: 300; } } }

.widjet-search {
  .uk-search-default {
    display: block;
    position: relative;
    width: 100%; }
  .uk-search-input {
    display: block;
    width: 100%;
    background-color: #ffffff;
    @include hh {
      background-color: #ffffff; }
    @include placeholder() {
      color: #838383;
      font-size: 16px;
      font-weight: 400; } }

  .uk-search-icon {
    width: 62px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -12px;
      width: 1px;
      height: 24px;
      background-color: #dddddd; } } }

.widjet-category {
  .widjet__title {
    margin-bottom: 17px; } }

.list-category {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    a {
      display: flex;
      align-items: center;
      color: #222;
      font-size: 18px;
      @include fz(18, 14);
      font-weight: 400;
      line-height: 36px;
      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        font-size: 17px;
        margin-right: 13px;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon fill='none' stroke='%23000' stroke-width='1.1' points='8.5 7 13.5 10 8.5 13'%3E%3C/polygon%3E%3Ccircle fill='none' stroke='%23000' stroke-width='1.1' cx='10' cy='10' r='9'%3E%3C/circle%3E%3C/svg%3E");
        @include tr(0.5s); }
      @include tr(0.5s);
      @include hh {
        text-decoration: none;
        color: $accent;
        @include tr(0.5s);
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon fill='none' stroke='%23efb007' stroke-width='1.1' points='8.5 7 13.5 10 8.5 13'%3E%3C/polygon%3E%3Ccircle fill='none' stroke='%23efb007' stroke-width='1.1' cx='10' cy='10' r='9'%3E%3C/circle%3E%3C/svg%3E");
          @include tr(0.5s); } } } } }

.list-articles {
  margin: 0;
  padding: 0;
  list-style-type: none; }
.list-articles-item {
  display: block;
  position: relative;
  & + .list-articles-item {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #dfe1ee; }
  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include hh {
      text-decoration: none; } }
  &__img {
    width: 75px;
    height: 75px;
    flex: 0 0 75px;
    margin-right: 20px; }
  &__info {
    display: block; }
  &__title {
    color: #222;
    font-size: 18px;
    @include fz(18, 14);
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 10px; }
  &__date {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 14px;
    font-weight: 400;
    .uk-icon {
      color: $accent;
      margin-right: 5px; } } }

.widjet-gallery {
  max-width: 350px;
  .uk-grid-collapse, .uk-grid-column-collapse {
    margin-left: -5px; }
  .uk-grid-collapse>*, .uk-grid-column-collapse>* {
    padding-left: 5px; }
  .uk-grid+.uk-grid-collapse, .uk-grid+.uk-grid-row-collapse, .uk-grid-collapse>.uk-grid-margin, .uk-grid-row-collapse>.uk-grid-margin {
    margin-top: 5px; } }

.widjet-tags-cloud {
  .tags-list {
    li {
      a {
        border-color: #ffffff;
        @include r(959) {
          border-color: #ddd;
          @include h {
            border-color: $accent; } } } } } }

.article-full__image .video > a:before,
.article-intro__image .video > a:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
  background-image: url(../img/ico-video.png);
  content: ''; }

.tags-list {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style-type: none; }

.tags-list li {
  margin-right: 5px;
  margin-bottom: 5px; }

.tags-list li a {
  -webkit-box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  padding: 0 20px;
  border: 2px solid #dddddd;
  background-color: #ffffff;
  color: #222222;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.86;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s; }


.tags-list li a:hover,
.tags-list li a:focus,
.tags-list li a:active {
  background-color: #edb509;
  border-color: #edb509;
  color: #ffffff;
  text-decoration: none; }
