@include font("Barlow" , "Barlow-ExtraLight", 100, normal);
@include font("Barlow" , "Barlow-Thin"      , 200, normal);
@include font("Barlow" , "Barlow-Light"     , 300, normal);
@include font("Barlow" , "Barlow-Regular"   , 400, normal);
@include font("Barlow" , "Barlow-Italic"    , 400, italic);
@include font("Barlow" , "Barlow-Medium"    , 500, normal);
@include font("Barlow" , "Barlow-SemiBold"  , 600, normal);
@include font("Barlow" , "Barlow-Bold"      , 700, normal);
@include font("Barlow" , "Barlow-ExtraBold" , 800, normal);
@include font("Barlow" , "Barlow-Black"     , 900, normal);
