.s-reasons {
  display: block;
  position: relative;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @include r(1199) {
      flex-direction: column; }
    & > * {
      width: 50%;
      @include r(1199) {
        width: 100%; } } }
  &__media {
    display: block;
    position: relative;
    .uk-cover {
      @include r(1439) {
        left: calc(50% + 50px); }
      @include r(1199) {
        left: 50%; } } }
  &__info {
    padding: 40px 100px;
    padding: 40px vw(100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    @include r(1599) {
      padding: 40px; }
    @include r(959) {
      padding: 30px; }
    @include r(639) {
      padding: 30px 15px; }

    .section-title {
      margin-bottom: 10px; }

    .section-content {
      color: #222;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      @include fz(24, 18); } } }

.reason-item {
  display: block;
  position: relative;
  &__img {
    display: block;
    position: relative;
    width: 100%; }
  &__body {
    display: flex;
    align-items: flex-start;
    padding-top: 30px;
    @include r(767) {
      padding-top: 20px; } }
  &__icon {
    flex: 0 0 50px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    @include r(767) {
      padding-bottom: 5px; } }
  &__desc {
    display: block;
    position: relative;
    padding-left: 25px;
    @include r(767) {
      padding-left: 15px; } }
  &__title {
    color: #222;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 5px 0;
    @include fz(22, 16); }
  &__text {
    color: #555;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2;
    margin: 0;
    @include fz(18, 14); } }
