.s-stats {
  display: block;
  position: relative;
  .uk-container {
    padding-bottom: 70px; } }

.stat-item {
  display: block;
  position: relative;
  background-color: white;
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
  padding: 40px;
  padding: vw(40px);
  border-right: 2px solid $accent;
  @include r(639) {
    padding: 20px; }
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
  &__title {
    color: #222;
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    @include fz(22, 16); }
  &__value {
    color: #222;
    font-size: 80px;
    line-height: 0.9;
    font-weight: 500;
    margin-bottom: 20px;
    @include fz(80, 50); }
  &__text {
    color: #888;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    margin: 0;
    @include fz(16, 14); } }
