.s-video {
  display: block;
  position: relative;
  background-color: #222;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @include r(767) {
      flex-direction: column; }
    & > * {
      width: 50%;
      @include r(767) {
        width: 100%; } } }
  &__desc {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    background-image: url(../img/bg_video.png);
    color: #fff;
    padding: 40px 150px;
    padding: 40px vw(150px);
    @include r(959) {
      padding: 30px 90px 30px 30px; }
    @include r(767) {
      padding: 30px 30px 50px 30px; }
    @include r(639) {
      padding: 30px 15px 30px 15px;
      text-align: center; }
    .section-title {
      span {
        color: $accent;
        &::before {
          display: none; } }
      h3 {
        color: #fff; } }
    .section-content {
      a.more {
        color: #fff;
        @include r(639) {
          display: flex;
          justify-content: center; } } } }
  &__img {
    display: block;
    @include r(767) {
      position: relative; }
    .uk-cover-container {
      min-height: 100%;
      @include r(767) {
        min-height: 250px; } } }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 110px;
    height: 110px;
    background-color: $accent;
    border-radius: 50%;
    outline: 10px solid rgba(#222, 0.2);
    @include r(767) {
      width: 80px;
      height: 80px; }
    img {
      margin-right: -5px; } } }
