.equipment-detail {
  display: block;
  position: relative;
  .section-title {
    margin-bottom: 35px;
    .uk-h2 {
      margin-bottom: 10px;
      font-size: 30px;
      @include fz(30, 20); } }
  &__gallery {
    margin-bottom: 40px;
    @include r(639) {
      margin-bottom: 25px; } }
  &__title {
    color: #222;
    font-size: 36px;
    @include fz(36, 22);
    font-weight: 700;
    margin-bottom: 10px; }
  &__location {
    color: #222;
    font-size: 18px;
    @include fz(18, 14);
    font-weight: 400;
    line-height: 1.44;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
    .uk-icon {
      color: $accent;
      margin-right: 10px; } }
  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px 0 60px;

    @include r(639) {
      flex-direction: column;
      margin: 25px 0; }

    a {
      width: calc(50% - 5px);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      line-height: 67px;
      background-color: #fff9e7;
      color: #222;
      font-size: 18px;
      font-weight: 500;
      @include tr(0.3s);
      @include hh {
        text-decoration: none;
        background-color: $accent;
        color: #ffffff;
        svg {
            color: #ffffff; } }

      svg {
        color: $accent;
        margin-right: 10px;
        @include tr(0.3s); }

      @include r(639) {
        width: 100%;
        margin-bottom: 10px; } } }

  &__desc {}

  &__specification {
    margin-top: 60px;

    @include r(639) {
      margin-top: 40px; }

    table {
      tr {
        td:last-child {
          font-weight: 600; } } } } }

.equipment-widjet-title {
  color: #222;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: relative;
  @include fz(18, 14);
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  &:before {
    content: '';
    display: inline-block;
    width: 22px;
    height: 30px;
    background-image: url(../img/svgico/logo-icon.svg);
    margin-right: 10px; } }

.equipment-order {
  background-color: #222;
  border-left: 5px solid $accent;
  padding: 50px 20px;
  text-align: center;
  &__title {
    color: white;
    font-size: 18px;
    font-weight: 400; }
  &__price {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .current-price {
      color: #ffffff;
      color: white;
      font-size: 48px;
      @include fz(48, 30);
      line-height: 1;
      font-weight: 700;
      text-align: center; }
    .old-price {
      color: #888;
      font-size: 24px;
      font-weight: 400;
      text-decoration: line-through; } } }

.equipment-contacts {
  display: block;
  position: relative;
  background-color: #fff;
  padding: 30px;
  margin: 45px 0;
  @include r(767) {
    padding: 0; }

  .contacts-list {
    li {
      & + li {
        margin-top: 5px; }
      a {
        color: #222;
        .uk-icon {
          width: 20px;
          height: 20px;
          flex: 0 0 20px; }
        .label {
          color: #222;
          font-weight: 700; } } } }

  .uk-button {
    padding: 0 10px;
    & + .uk-button {
      margin: 10px 0 0 0; } } }

.equipment-form {
  display: block;
  position: relative;
  background-color: #fff;
  padding: 30px;
  @include r(767) {
    padding: 0; }

  .nice-select {
    display: flex;
    align-items: center;
    height: 62px;
    border: none;
    padding-left: 30px;
    border-radius: 2px;
    background-color: #f7f7f7;
    .current {
      color: #888;
      font-size: 18px;
      font-weight: 400;
      @include fz(18, 14); }
    .option {
      padding-left: 30px; } } }

.list-checked {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.list-checked li {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start; }

.list-checked li + li {
  margin-top: 5px; }

.list-checked li::before {
  display: block;
  width: 17px;
  height: 17px;
  margin-right: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23efb007' stroke-width='1.1' points='4,10 8,15 17,4'%3E%3C/polyline%3E%3C/svg%3E");
  content: ''; }
